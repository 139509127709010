import React, { useEffect, useRef } from 'react';
import { COMMON_BASE_PAGE_X_PADDING } from '../../constants';
import TopNav from '../../../../../common/components/TopNav';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  useToast,
} from '@chakra-ui/react';
import FormContainer from '../../FormContainer';
import PageBase from '../../../../../common/components/PageBase';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../../site/siteSlice';
import useViewportType from '../../../../../common/hooks/use-viewport-type';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RealTimeTariffFormData } from './types';
import { REAL_TIME_FORM_DEFAULT_VALUE } from './constants';
import { useGetTariffTemplatesQuery } from '../../tariffApi';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';
import { useTariffFormContext } from '../../tariff-form-context';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import { realTimeFormSchema } from './validation-schema';
import RouteChangeBlocker from '../../../../../common/components/RouteChangeBlocker';
import { customIsEqual } from '../../utils';

export default function RealTimeTariffForm() {
  const { tariffId } = useParams<{ tariffId: string }>();
  const site = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const {
    formData: { basicDetails, realTimeRates },
  } = useTariffFormContext();
  const defaultValues = realTimeRates ?? REAL_TIME_FORM_DEFAULT_VALUE;
  const form = useForm<RealTimeTariffFormData>({
    resolver: yupResolver(realTimeFormSchema),
    defaultValues,
  });
  const { onUpdateFormData } = useTariffFormContext();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    reset,
    getValues,
  } = form;
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const [search] = useSearchParams();
  const backURL = search.get('backURL');
  const {
    queryResult: { isLoading },
    initialFormValue,
  } = usePopulateRealTimeRatesFromQuery(reset, defaultValues);

  if (!basicDetails) {
    return (
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <TopNav backURL={`/site/${site.site_id}/bills`} title="Review & Save" />

        <Alert mt={5} status="error">
          <AlertIcon />
          Error loading energy rate data: basic details do not exist!
        </Alert>
      </PageBase>
    );
  }

  async function handleSubmitRealTimeRateForm(realTimeRates: RealTimeTariffFormData) {
    onUpdateFormData((p) => ({ ...p, realTimeRates }));
    navigate(`/site/${site.site_id}/bills/tariffs/${tariffId}/review`);
  }

  function handleSubmissionError() {
    toast({
      title: 'Invalid rates',
      description: 'Check that all required fields are filled out',
      status: 'error',
    });
  }

  function checkForUnsavedChanges() {
    if (isSubmitting) return false;
    // NOTE: Templated values are asynchronously populated (after form is initialized), we use a ref to track this
    return !customIsEqual(initialFormValue, getValues());
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <FormProvider {...form}>
          <TopNav
            backURL={backURL ?? `/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details`}
            title="Real-time Rate Configuration"
          />

          {isLoading ? (
            <CenteredLoader />
          ) : (
            <>
              <Box px={5}>
                <Alert status="info" variant="left-accent">
                  <AlertIcon />
                  This will take about a minute. Please make sure you have your bill with you for reference.
                </Alert>
              </Box>

              <Heading ml={2} mt={3} size="sm">
                IMPORT RATES
              </Heading>

              <Box
                onSubmit={handleSubmit(handleSubmitRealTimeRateForm, handleSubmissionError)}
                data-testid="real-time-rates-form"
                as="form"
              >
                <FormContainer>
                  {isDesktopViewport && (
                    <Heading mb={2} size="md">
                      Configure Your Rates
                    </Heading>
                  )}

                  <Alert mt={3} status="success" variant="left-accent">
                    <AlertIcon />
                    Your supply charge and site configuration has been auto-populated based on your distributor.
                  </Alert>

                  <FormControl
                    w={['100%', '100%', '50%', '30%']}
                    isInvalid={!!errors?.seasons?.[0]?.dailySupplyCharge}
                    mt={3}
                  >
                    <FormLabel fontWeight="bold">
                      Daily supply charge
                      <InfoPopover>
                        Your daily supply charge is a flat daily rate which you pay for electricity, on top of your
                        other rates.{' '}
                        {!!site.distributor_id && "We've automatically populated this value based on your distributor."}
                      </InfoPopover>
                    </FormLabel>
                    <InputGroup>
                      <Input
                        data-testid="daily-supply-charge"
                        {...register('seasons.0.dailySupplyCharge')}
                        type={'number'}
                        min={0}
                        step="0.0000000001"
                      />
                      <InputRightAddon>$ / day</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage data-testid="daily-supply-charge-error-message">
                      {errors?.seasons?.[0]?.dailySupplyCharge?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl w={['100%', '100%', '50%', '30%']} isInvalid={!!errors?.seasons?.[0]?.monthlyFee} mt={3}>
                    <FormLabel fontWeight="bold">Monthly Fee</FormLabel>
                    <InputGroup>
                      <Input
                        data-testid="monthly-fee"
                        {...register('seasons.0.monthlyFee')}
                        type={'number'}
                        min={0}
                        step="0.0000000001"
                      />
                      <InputRightAddon>$ / month</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage data-testid="monthly-fee-error-message">
                      {errors?.seasons?.[0]?.monthlyFee?.message}
                    </FormErrorMessage>
                  </FormControl>
                </FormContainer>

                <Center mt={6}>
                  <Button
                    data-testid="submit-real-time-tariff-form"
                    type="submit"
                    w={['75%', '75%', '50%']}
                    rounded={20}
                    colorScheme="dusk100"
                  >
                    Next
                  </Button>
                </Center>
              </Box>
            </>
          )}
        </FormProvider>
      </PageBase>
    </RouteChangeBlocker>
  );
}

/**
 * Manages making a query to fetch real-time rate templates for the specifiec retailer/distributor and populating
 * the appropriate values into the form.
 *
 * @param reset - The function used to reset form values
 * @param defaultValues - The default form values (populated by the API when loading an existing tariff). This is used
 *                        to track the initial form value to compare against the current value when a route change event
 *                        occurs, and display an unsaved changes alert dialog to the user.
 * @returns An object containing the original query result, and the initial form values (either populated from the
 *          template, or passed through when the tariff is an existing one.
 */
function usePopulateRealTimeRatesFromQuery(
  reset: UseFormReset<RealTimeTariffFormData>,
  defaultValues: RealTimeTariffFormData
) {
  const { tariffId } = useParams<{ tariffId: string }>();
  const site = useSelector(selectSite);
  const {
    formData: { basicDetails, realTimeRates },
  } = useTariffFormContext();
  const queryResult = useGetTariffTemplatesQuery(
    {
      distributor_id: site.distributor_id!,
      retailer_id: basicDetails?.retailer.value ?? 0,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.[0],
      }),
      // Existing tariffs don't need a template -- they have data already.
      skip: tariffId !== 'new',
    }
  );
  const { data: realTimeTemplate, isLoading, isError } = queryResult;
  // Tracks the initially-populated tariff template value, so it can be compared to discern unsaved changes.
  const initialFormValue = useRef<RealTimeTariffFormData>(defaultValues);

  useEffect(() => {
    if (!isLoading && !isError && realTimeTemplate && !realTimeRates) {
      const monthlyFee = realTimeTemplate.tariff_rates.find(
        (rate) => rate.charge_type === 'FIXED_PRICE' && rate.charge_class === 'FEES'
      );
      const dailySupplyCharge = realTimeTemplate.tariff_rates.find(
        (rate) => rate.charge_type === 'FIXED_PRICE' && rate.charge_class === 'DISTRIBUTION'
      );
      const hasSolarFeedIn = !!realTimeTemplate.tariff_rates.find((rate) => rate.transaction_type === 'SELL_EXPORT');
      const hasControlledLoad = !!realTimeTemplate.tariff_rates.find(
        (rate) => rate.charge_class === 'DEDICATED_CIRCUIT_1'
      );

      const initialValue = {
        ...REAL_TIME_FORM_DEFAULT_VALUE,
        seasons: [
          {
            ...REAL_TIME_FORM_DEFAULT_VALUE.seasons[0],
            hasControlledLoad,
            hasSolarFeedIn,
            dailySupplyCharge: dailySupplyCharge?.rate_bands[0]?.rate ?? 0,
            monthlyFee: monthlyFee?.rate_bands[0].rate ?? 0,
          },
        ],
      };

      reset(initialValue);
      initialFormValue.current = initialValue;
    }
  }, [realTimeTemplate, isLoading, isError, reset, realTimeRates]);

  return { queryResult, initialFormValue: initialFormValue.current };
}
