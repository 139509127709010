import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectSite, updateSiteData } from '../../site/siteSlice';
import { useAuthoriseSensiboMutation, useDeleteSensiboAuthorisationMutation } from './sensiboApi';
import { useReduxDispatch } from '../../../app/store';
import { ENDPOINT, IS_RUNNING_CYPRESS_TESTS } from '../../../common/constants';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser, InAppBrowserEvent } from '@awesome-cordova-plugins/in-app-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { useCallback, useEffect, useRef } from 'react';

function getRedirectURI() {
  const envMode = import.meta.env?.MODE;
  const domain = `app${envMode === 'staging' ? '-staging' : ''}.clipsalcortex.com`;
  if (envMode !== 'development' && !IS_RUNNING_CYPRESS_TESTS) return `https://${domain}`;
  return 'http://localhost:3030';
}

export default function useIntegrateSensibo(isLoaded: boolean, onOpen?: () => void, onClose?: () => void) {
  const { site_id: siteId, integrations } = useSelector(selectSite);
  const { search, pathname } = useLocation();
  const [authoriseSensibo, { isLoading: isAuthorising }] = useAuthoriseSensiboMutation();
  const [deleteSensiboAuthorisation, { isLoading: isDeletingAuthorisation }] = useDeleteSensiboAuthorisationMutation();
  const toast = useToast({ isClosable: true, status: 'success', duration: 5000 });
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  /* istanbul ignore next -- @preserve */
  const isNativePlatform = Capacitor.isNativePlatform();

  const handleAuthoriseSensibo = useCallback(
    async (authorisationCode: string) => {
      const response = await authoriseSensibo({ siteID: siteId, authorisationCode });
      const isError = 'error' in response;
      if (!isError) {
        dispatch(updateSiteData({ integrations: { ...integrations, sensibo: true } }));
        toast({ title: 'Sensibo Integration Successful' });
        if (onClose) onClose();
      } else {
        toast({ title: 'Sensibo Integration Failed', description: 'Please try again later', status: 'error' });
      }
    },
    [siteId, authoriseSensibo, dispatch, integrations, toast, onClose]
  );

  const handleSensiboLogin = () => {
    const redirectURI = getRedirectURI();
    const url = `https://home.sensibo.com/o/authorize/?response_type=code&client_id=${
      import.meta.env?.VITE_SENSIBO_CLIENT_ID
    }&redirect_uri=${ENDPOINT}/v1/sensibo_redirect&state=${redirectURI}/site/${siteId}/live`;

    // use controlled webview for native platforms
    /* istanbul ignore next -- @preserve */
    if (isNativePlatform) {
      const browser = InAppBrowser.create(url, '_blank', {
        beforeload: 'get',
        location: 'no',
      });

      const beforeloadObservable = browser.on('beforeload');

      beforeloadObservable.subscribe(async (value: InAppBrowserEvent) => {
        if (value.url.startsWith(redirectURI)) {
          const params = value.url.split('?')[1] || '';
          const urlParams = new URLSearchParams(params);
          const authorisationCode = urlParams.get('code');
          if (authorisationCode) handleAuthoriseSensibo(authorisationCode);
          browser.close();
        } else {
          browser._loadAfterBeforeload(value.url);
        }
      });
    } else {
      Browser.open({ url, windowName: '_self' });
    }
  };

  const handleSensiboLogout = async () => {
    try {
      await deleteSensiboAuthorisation(siteId).unwrap();
      dispatch(updateSiteData({ integrations: { ...integrations, sensibo: false } }));
      toast({ title: 'Sensibo Integration deleted!' });
      if (onClose) onClose();
    } catch (error) {
      toast({
        title: 'Sensibo Integration deletion failed!',
        description: 'Please try again later',
        status: 'error',
      });
    }
  };

  const hasLoaded = useRef(false);

  const authoriseSensiboDevice = useCallback(async () => {
    const urlParams = new URLSearchParams(search);
    const isSensiboIntegrationDrawerOpen = urlParams.get('isSensiboIntegrationDrawerOpen') === 'true';
    const authorisationCode = urlParams.get('code');
    if (isSensiboIntegrationDrawerOpen && onOpen) onOpen();

    // we run this in web only
    if (authorisationCode && !isNativePlatform) {
      // prevents double trigger in useEffect when in dev
      hasLoaded.current = true;
      await handleAuthoriseSensibo(authorisationCode);
      navigate(pathname, { replace: true });
    }
  }, [search, onOpen, isNativePlatform, handleAuthoriseSensibo, navigate, pathname]);

  useEffect(() => {
    if (isLoaded && !hasLoaded.current) authoriseSensiboDevice();
  }, [authoriseSensiboDevice, isLoaded]);

  return {
    isAuthorising,
    handleSensiboLogin,
    isDeletingAuthorisation,
    handleSensiboLogout,
  };
}
