import { Avatar, AvatarProps, Image, ImageProps, SkeletonCircle, SkeletonProps } from '@chakra-ui/react';
import React from 'react';
import { useGetRetailerImageURLQuery } from './billsApi';
import { EMPTY_RETAILER } from '../../common/constants';

interface Props {
  id: number;
  size: string;
  name?: string;
  skip?: boolean;
  skeletonProps?: SkeletonProps;
  imageProps?: ImageProps;
  avatarProps?: AvatarProps;
}

const emptyObject = {};

export function RetailerImage({
  id,
  size,
  name = 'Retailer',
  skip = false,
  skeletonProps = emptyObject,
  imageProps = emptyObject,
  avatarProps = emptyObject,
}: Props) {
  const skipRequest = id === EMPTY_RETAILER.id ? true : skip;
  const { data, isError } = useGetRetailerImageURLQuery(id, { skip: skipRequest });
  const imageBase64 = data?.base64_file ?? '';
  const skeletonSize =
    {
      sm: '32px',
      md: '48px',
      lg: '64px',
      xl: '96px',
    }[size] ?? size;

  return isError || !imageBase64 ? (
    <Avatar alignSelf="center" size={size} name={name} {...avatarProps} />
  ) : (
    <SkeletonCircle h={skeletonSize} w={skeletonSize} alignSelf={'center'} isLoaded={!!imageBase64} {...skeletonProps}>
      <Image
        alignSelf={'center'}
        src={imageBase64 ? 'data:imageBase64/png;base64,' + imageBase64 : undefined}
        alt={'Retailer image'}
        {...imageProps}
      />
    </SkeletonCircle>
  );
}
