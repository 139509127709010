import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import MultiToggleSwitch from '../../../../../common/components/MultiToggleSwitch';
import { useTariffFormContext } from '../../tariff-form-context';
import Highcharts from 'highcharts';
import TimeSpanChart from '../TimeSpanChart';
import theme from '../../../../../styles/theme';

const TIERED_CHART_HEIGHT_PX = 220;
const COLORS = [
  theme.colors.offPeakGreen[500],
  theme.colors.ellipse[500],
  theme.colors.peakRateRed[500],
  theme.colors.boughtFromGrid[500],
  theme.colors.customBlue[500],
];

export default function FlatRateSummary() {
  const {
    formData: { flatRates },
  } = useTariffFormContext();

  const [visibleSeasonIndex, setVisibleSeasonIndex] = useState(0);
  const multiToggleSwitchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch');
  const switchOptions = useMemo(
    () =>
      flatRates!.seasons.map((season, seasonIndex) => ({
        label: season.name || `Season ${seasonIndex + 1}`,
        value: seasonIndex.toString(),
      })),
    [flatRates]
  );
  const displayedSeason = flatRates!.seasons[visibleSeasonIndex];

  const stackedTieredSeries = useMemo<Highcharts.SeriesOptionsType[]>(() => {
    const rate = displayedSeason.rate;
    const data = [];
    const fromTime = new Date(2021, 0, 1, 0, 0, 0, 0);
    const toTime = new Date(2021, 0, 2, 0, 0, 0, 0);

    while (fromTime.getTime() < toTime.getTime()) {
      const color = COLORS[0];
      data.push({
        color,
        borderColor: color,
        x: new Date(fromTime).getTime(),
        y: rate,
      });
      fromTime.setMinutes(fromTime.getMinutes() + 1);
    }
    return [
      {
        data,
        yAxis: 0,
        type: 'column',
      },
    ];
  }, [displayedSeason.rate]);

  return (
    <>
      {flatRates!.seasons.length > 1 && (
        <Box mt={3} data-testid="season-multi-toggle-switch">
          <MultiToggleSwitch
            className={multiToggleSwitchClassName}
            value={visibleSeasonIndex.toString()}
            onChange={(newSeasonIndex) => {
              setVisibleSeasonIndex(Number(newSeasonIndex));
            }}
            switchOptions={switchOptions}
          />
        </Box>
      )}
      <Box minH={`calc(${TIERED_CHART_HEIGHT_PX}px + 30px)`}>
        <Heading mt={2} size="sm">
          EVERY DAY
        </Heading>
        <TimeSpanChart series={stackedTieredSeries} height={TIERED_CHART_HEIGHT_PX} />

        <Flex align="center" mt={2}>
          <Box mr={2} rounded={100} h={8} w={8} bg={COLORS[0]} />
          <Box w="100%">
            <Flex align="center" justify="space-between">
              <Text fontWeight="bold">Flat Rate</Text>
              <Text>
                ${' '}
                <Box as="span" fontWeight="bold">
                  {(displayedSeason.rate ?? 0).toFixed(2)}
                </Box>{' '}
                / kWh
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
