import React from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useReduxDispatch } from '../../app/store';
import { selectIsExperimentalFeaturesModalOpen, toggleExperimentalFeaturesModal } from './profileSlice';
import { ExperimentalFeature, getFlagValue, setFlagValue } from '../../utils/common/experimental-feature-helpers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

type FormData = yup.InferType<typeof validationSchema>;

const validationSchema = yup.object({
  customEndpoint: yup.string(),
  enableEnergyMastersBranding: yup.boolean(),
});

const getFormOptions = () => ({
  defaultValues: getInitialFormValues(),
  resolver: yupResolver(validationSchema),
});

export default function ExperimentalFeaturesModal() {
  const { onClose } = useDisclosure();
  const dispatch = useReduxDispatch();
  const toast = useToast();
  const isExperimentalFeaturesModalOpen = useSelector(selectIsExperimentalFeaturesModalOpen);

  function handleClose() {
    dispatch(toggleExperimentalFeaturesModal(false));
    onClose();
  }

  function handleSubmitExperimentalFeaturesForm(formData: FormData) {
    // Set each value in local storage when submitted
    Object.entries(formData)
      .filter(([fieldName]) => fieldName !== 'customEndpoint')
      .forEach(([fieldName, value]) => {
        setFlagValue(fieldName as ExperimentalFeature, value as boolean);
      });

    toast({
      title: `Successfully updated experimental features`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });

    handleClose();

    // required for theme to update
    window.location.reload();
  }

  return (
    <Modal
      size={'xl'}
      isCentered
      onClose={handleClose}
      isOpen={isExperimentalFeaturesModalOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Experimental Features</ModalHeader>
        <ModalCloseButton data-testid="close-experimental-features-modal" />

        <ModalBody data-testid="experimental-features-modal">
          <Alert status="info">
            <AlertIcon />
            <Flex>
              <Box as={'span'}>
                Use the options below to enable experimental features in the app. These features are{' '}
                <Box as={'span'} fontWeight={'900'}>
                  experimental
                </Box>
                , so please use with knowledge that they may not be fully functional.
              </Box>
            </Flex>
          </Alert>

          <ExperimentalFeaturesForm onSubmit={handleSubmitExperimentalFeaturesForm} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function getInitialFormValues(): FormData {
  return {
    [ExperimentalFeature.EnergyMastersBranding]: getFlagValue(ExperimentalFeature.EnergyMastersBranding),
    customEndpoint: localStorage.getItem('customEndpoint') ?? '',
  };
}

interface FormProps {
  onSubmit: (values: FormData) => void;
}

function ExperimentalFeaturesForm({ onSubmit }: FormProps) {
  const formOptions = getFormOptions();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<FormData>(formOptions);
  const fieldLabels = {
    [ExperimentalFeature.EnergyMastersBranding]: 'Enable Energy Masters branding?',
  };

  return (
    <Flex
      direction={'column'}
      justify={'center'}
      align={'center'}
      width={'100%'}
      as={'form'}
      mb={2}
      mt={4}
      onSubmit={handleSubmit(onSubmit)}
    >
      {Object.entries(ExperimentalFeature).map(([_, enumValue], i) => (
        <FormControl isInvalid={!!errors?.[enumValue as ExperimentalFeature]} key={`experimental-feature-field-${i}`}>
          <Checkbox
            {...register(enumValue as ExperimentalFeature)}
            id={enumValue}
            data-testid={`${enumValue}-checkbox`}
          >
            {fieldLabels[enumValue]}
          </Checkbox>
        </FormControl>
      ))}

      <Button
        isDisabled={!isDirty}
        alignSelf={'start'}
        type={'submit'}
        colorScheme="primaryBranding"
        mt={3}
        data-testid="save-experimental-features-btn"
      >
        Save
      </Button>
    </Flex>
  );
}
