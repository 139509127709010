import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type EVChargerState = {
  isChargingCurrentControlOpen: boolean;
};

export type EVChargerReducers = {
  updateIsChargingCurrentControlOpen: (state: EVChargerState, action: PayloadAction<boolean>) => void;
};

const initialState: EVChargerState = {
  isChargingCurrentControlOpen: false,
};

export const evChargerSlice = createSlice<EVChargerState, EVChargerReducers>({
  name: 'evCharger',
  initialState,
  reducers: {
    updateIsChargingCurrentControlOpen: (state, { payload }) => {
      return { ...state, isChargingCurrentControlOpen: payload };
    },
  },
});

export const { updateIsChargingCurrentControlOpen } = evChargerSlice.actions;

export const selectIsChargingCurrentControlOpen = (state: RootState) => {
  return state.evCharger.isChargingCurrentControlOpen;
};

export default evChargerSlice.reducer;
