import Card from 'clipsal-cortex-ui/src/components/card/Card';
import React, { useState } from 'react';
import { useTariffs } from './tariffApi';
import TariffListItem from './TariffListItem';
import { Box, Button, Center, Flex, Grid, Heading, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import StandalonePagination from '../../../common/components/StandalonePagination';
import { ITEMS_PER_PAGE, LIST_ITEM_HEIGHT_PX } from './constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';

type Props = {
  isParentLoaded: boolean;
};

export default function TariffsList({ isParentLoaded }: Props) {
  const [state, setState] = useState({ offset: 0 });
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { offset } = state;
  const {
    data: { results: tariffs, count },
    isFetching,
  } = useTariffs({ offset, limit: ITEMS_PER_PAGE });
  const totalNumPages = Math.ceil(count / ITEMS_PER_PAGE);
  const siteHasTariffs = isFetching || !!tariffs.length;
  const tariffListContents =
    isFetching || !isParentLoaded ? (
      <TariffListSkeleton />
    ) : (
      tariffs.map((t, i) => (
        <TariffListItem
          offset={offset}
          containerProps={{ 'data-testid': `tariff-list-item-${i}` }}
          tariff={t}
          successiveTariff={i !== 0 ? tariffs[i - 1] : null}
          key={t.id}
        />
      ))
    );

  return (
    <Card data-testid="past-energy-rates">
      <Flex justify="space-between">
        <Heading size={'md'}>My Energy Rates</Heading>
        {siteHasTariffs && (
          <Button
            data-testid="new-rate-btn"
            onClick={() => navigate(`/site/${siteId}/bills/tariffs/new`)}
            colorScheme="dusk100"
            rounded={20}
          >
            New Rate
          </Button>
        )}
      </Flex>
      <Box minHeight={siteHasTariffs ? `${LIST_ITEM_HEIGHT_PX * ITEMS_PER_PAGE}px` : undefined}>
        {siteHasTariffs ? tariffListContents : <NoTariffsFound />}
      </Box>

      {!isFetching && totalNumPages > 1 && (
        <StandalonePagination
          isLoaded={!isFetching}
          containerProps={{
            mt: 3,
            'data-testid': 'tariff-list-pagination',
          }}
          totalNumPages={Math.ceil(count / ITEMS_PER_PAGE)}
          currentPageNumber={offset / 5 + 1}
          onChangeToPage={(pageNum) => {
            setState({
              ...state,
              offset: pageNum * 5 - 5,
            });
          }}
        />
      )}
    </Card>
  );
}

function TariffListSkeleton() {
  return (
    <>
      {Array.from(Array(5)).map((_, index) => {
        return (
          <Grid
            templateColumns={'50px 1fr'}
            key={index}
            my={'2px'}
            h={`calc(${LIST_ITEM_HEIGHT_PX}px - 4px)`}
            width={'100%'}
            borderBottom="1px solid"
            // borderTop={index === 0 ? '1px solid' : undefined}
            borderColor="darkModeBorderColor.500"
            _dark={{
              borderColor: 'textGrey.500',
            }}
          >
            <Center>
              <SkeletonCircle size="12" />
            </Center>
            <Flex align={'flex-start'} justify={'center'} ml={2} width="100%" flexDirection="column">
              <Skeleton h={'18px'} width={'70%'} />
              <Skeleton mt={2} h={'14px'} width={'50%'} />
            </Flex>
          </Grid>
        );
      })}
    </>
  );
}

function NoTariffsFound() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);

  return (
    <Center flexDirection={'column'} height="100%">
      <Heading color={'noBillsReceivedHeading.500'} mb={2} mt={4} size={'lg'}>
        No Energy Rates Found
      </Heading>
      <Button
        mt={2}
        mb={4}
        rounded={20}
        colorScheme="dusk100"
        onClick={() => navigate(`/site/${siteId}/bills/tariffs/new`)}
      >
        Add Energy Rate
      </Button>
    </Center>
  );
}
