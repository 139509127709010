import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import openLinkInCortexApp from '../../utils/native/browser';
import { ChevronRightIcon } from '@chakra-ui/icons';
import ProfilePageBase from './ProfilePageBase';

const CONTACT_AND_SUPPORT_PAGES = [
  {
    title: 'Hardware Support',
    link: 'https://clipsalsolar.zendesk.com/hc/en-us/categories/4406043428889-Clipsal-Pulse-app-for-Consumers',
  },
  {
    title: 'Installation Issues',
    link: 'https://clipsalsolar.zendesk.com/hc/en-us/categories/4406043428889-Clipsal-Pulse-app-for-Consumers',
  },
  {
    title: 'App Issues and Feedback',
    link: 'https://clipsalsolar.zendesk.com/hc/en-us/categories/4406043428889-Clipsal-Pulse-app-for-Consumers',
  },
  {
    title: 'General Feedback/Others',
    link: 'https://clipsalsolar.zendesk.com/hc/en-us/categories/4406043428889-Clipsal-Pulse-app-for-Consumers',
  },
];

const Contact = () => {
  const background = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  return (
    <ProfilePageBase title={'Contact & Support'}>
      {CONTACT_AND_SUPPORT_PAGES.map((page) => (
        <Box bg={background} _hover={{ bg: hoverColor }} key={page.title}>
          <Flex
            borderBottom="1px solid"
            borderColor={borderColor}
            justify={'space-between'}
            align={'center'}
            py={8}
            px={6}
            cursor={'pointer'}
            onClick={() => openLinkInCortexApp(page.link)}
          >
            <Heading fontSize={18}>{page.title}</Heading>
            <ChevronRightIcon w={'26px'} h={'26px'} />
          </Flex>
        </Box>
      ))}
    </ProfilePageBase>
  );
};

export default Contact;
