import {
  Ticket,
  TicketComment,
  TicketCommentToSave,
  ZendeskAttachment,
  ZendeskUser,
} from 'clipsal-cortex-types/src/api/api-zendesk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Center, Divider, Flex, Heading, IconButton, Image, Input, Text, useToast } from '@chakra-ui/react';
import { get, post } from '../../common/api/api-helpers';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';
import { formatNiceDate, formatTime } from 'clipsal-cortex-utils/src/formatting/formatting';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDropzone } from 'react-dropzone';
import { UploadedFile } from './SubmitTicket';
import uploadAttachmentImage from '../../assets/images/upload_attachment.svg';
import { Browser } from '@capacitor/browser';

type ViewTicketProps = {
  ticket: Ticket | null;
  zendeskUser: ZendeskUser | null;
};

export function ViewTicket({ ticket, zendeskUser }: ViewTicketProps) {
  const [comments, setComments] = useState<TicketComment[]>([]);
  const [isLoaded, setLoaded] = useState(false);
  const { register, reset, handleSubmit: handleFormSubmit } = useForm<{ comment: string }>();
  const toast = useToast();
  const commentBoxRef = useRef<HTMLDivElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const handleFileUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading was aborted');
        };
        reader.onerror = () => {
          toast({
            title: 'Error reading file.',
            description: 'Please try uploading this file again.',
            status: 'error',
            isClosable: true,
          });
          console.log('file reading has failed');
        };
        reader.onload = async () => {
          const fileData = reader.result as string;

          setUploadedFiles([
            ...uploadedFiles,
            {
              name: file.name,
              url: fileData,
              fileData,
              type: file.type,
            },
          ]);
        };

        reader.readAsDataURL(file);
      });
    },
    [toast, uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ['image/*', 'application/pdf'],
    multiple: true,
  });

  const fetchCommentsForTicket = useCallback(async () => {
    if (!ticket) throw new Error('Ticket ID must be specified to fetch comments for a ticket');
    const comments = await get<TicketComment[]>(`/v1/tickets/${ticket.id}/comments`);

    setComments(comments);
    setLoaded(true);
    commentBoxRef.current?.scrollTo({ top: commentBoxRef.current?.scrollHeight });
  }, [ticket]);

  useEffect(() => {
    fetchCommentsForTicket();
  }, [fetchCommentsForTicket]);

  useInterval(async () => {
    if (isLoaded) {
      if (!ticket) throw new Error('Ticket ID must be specified to fetch comments for a ticket');
      const apiComments = await get<TicketComment[]>(`/v1/tickets/${ticket.id}/comments`);

      setComments(apiComments);
      setLoaded(true);
      if (apiComments.length !== comments.length) {
        commentBoxRef.current?.scrollTo({ top: commentBoxRef.current?.scrollHeight });
      }
    }
  }, 5000);

  async function handleSubmitNewComment({ comment }: { comment: string }) {
    if (!ticket?.id) throw new Error('Unable to submit comment without an associated ticket.');

    try {
      const body: TicketCommentToSave = { comment };

      try {
        // Add uploaded files to the ticket
        const savedUploadedFiles = await Promise.all(
          uploadedFiles.map((f) =>
            post<ZendeskAttachment>(`/v1/upload_file_to_zendesk`, {
              file_base64: f.fileData,
              file_name: f.name,
              content_type: f.type,
            })
          )
        );

        if (savedUploadedFiles.length) {
          body.attachment_tokens = savedUploadedFiles.map((savedFile) => savedFile.token);
        }
      } catch (e) {
        toast({
          title: `Error uploading attachments`,
          description: 'Please contact support directly about this issue.',
          status: 'error',
          isClosable: true,
        });
      }

      await post<Ticket>(`/v1/tickets/${ticket.id}/comments`, body);
      const comments = await get<TicketComment[]>(`/v1/tickets/${ticket.id}/comments`);

      setComments(comments);
      reset();
      commentBoxRef.current?.scrollTo({ top: commentBoxRef.current?.scrollHeight });
    } catch (e) {
      toast({
        title: 'Error submitting comment',
        description: 'Please contact support directly about this issue.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  function getCreatedDate() {
    if (!ticket?.id) throw new Error('Unable to submit comment without an associated ticket.');
    const createdDate = new Date(ticket?.created_at);
    const createdDateFormatted = formatNiceDate(createdDate);
    const createdTimeFormatted = formatTime(createdDate);

    return `${createdDateFormatted} at ${createdTimeFormatted}`;
  }

  function handleDeleteFile(file: UploadedFile) {
    setUploadedFiles(uploadedFiles.filter((f) => f.fileData !== file.fileData));
  }

  if (!ticket) return <Box>Error! No ticket specified.</Box>;
  if (!isLoaded) return <CenteredLoader />;

  return (
    <Flex direction="column">
      <Heading textAlign="center" size={'md'}>
        {ticket.subject}
      </Heading>
      <Heading mb={2} textAlign="center" size={'sm'}>
        Created on {getCreatedDate()}
      </Heading>
      <Flex ref={commentBoxRef} h="50vh" overflowY="auto" justify="flex-end" direction="column">
        {comments.map((c, i) => {
          // Hacky fix, when a user creates their first ticket (and thus creates their Zendesk user), the Zendesk system
          // takes a while to create the user and ticket as it has to iterate triggers and automations, which means
          // users won't have a zendesk user to identify their own comments.
          // For more info, see: shorturl.at/fpEN4
          const isUserAuthor = zendeskUser?.id === c.author_id || i === 0;
          const chatBubbleProps = {
            p: 3,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: isUserAuthor ? 10 : 3,
            borderBottomRightRadius: isUserAuthor ? 3 : 10,
            bg: isUserAuthor ? 'customBlue.500' : 'backgroundGrey.500',
            color: isUserAuthor ? 'white' : 'black',
          };
          const shouldShowAuthor = i === 0 || comments[i - 1].author_id !== c.author_id;

          return (
            <Box
              mt={shouldShowAuthor ? undefined : 2}
              key={c.id}
              alignSelf={isUserAuthor ? 'flex-end' : 'flex-start'}
              maxW={'75%'}
            >
              {shouldShowAuthor && (
                <Text fontSize={'sm'} justifySelf={isUserAuthor ? 'flex-end' : 'flex-start'}>
                  {isUserAuthor ? 'You' : 'Cortex Support'}
                </Text>
              )}
              <Box data-testid={`chat-bubble-${i}`} {...chatBubbleProps}>
                <Text>{c.body}</Text>
                <Flex>
                  {c.attachments.map((attachment, i) => (
                    <Box
                      cursor="pointer"
                      onClick={
                        /* istanbul ignore next -- @preserve */
                        async () => await Browser.open({ url: attachment.content_url })
                      }
                      key={i}
                    >
                      <Image
                        mt={1}
                        mr={i === 0 ? 1 : 0}
                        ml={c.attachments.length > 1 && i === c.attachments.length - 1 ? 1 : 0}
                        rounded={5}
                        w="75px"
                        h="75px"
                        alt={attachment.file_name}
                        src={attachment.content_type.includes('image') ? attachment.content_url : uploadAttachmentImage}
                      />
                      <Text noOfLines={1} maxW="75px" fontSize="xs">
                        {attachment.file_name}
                      </Text>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          );
        })}
      </Flex>

      <Divider my={3} />

      <Flex align="center" as={'form'} onSubmit={handleFormSubmit(handleSubmitNewComment)}>
        <Box w={'100%'}>
          <Flex>
            <Input
              isDisabled={!zendeskUser}
              data-testid="ticket-comment-input"
              {...register('comment')}
              variant="flushed"
              mr={1}
              placeholder={'Type something...'}
            />
            <Box rounded={20} {...getRootProps()}>
              <IconButton
                type="button"
                aria-label={'Attach item'}
                rounded={20}
                colorScheme="customBlue"
                icon={
                  <Box>
                    <AttachmentIcon w="20px" h="20px" />
                    <input {...getInputProps()} />
                  </Box>
                }
              />
            </Box>
          </Flex>
          {uploadedFiles.length ? (
            <Box>
              <Text mt={2} fontSize="sm">
                Attachments
              </Text>
              <Flex>
                {uploadedFiles.map((f, i) => (
                  <Center w="70px" h="70px" position="relative" key={i}>
                    <Center
                      as="button"
                      type="button"
                      onClick={() => handleDeleteFile(f)}
                      h="20px"
                      w="20px"
                      bg="black"
                      rounded={100}
                      position="absolute"
                      top={0}
                      right={0}
                    >
                      <CloseIcon fontSize="xs" color="white" />
                    </Center>
                    <Image
                      rounded={15}
                      w="50px"
                      h="50px"
                      objectFit={'cover'}
                      src={f.type.includes('image') ? f.url : uploadAttachmentImage}
                      alt={'file'}
                    />
                  </Center>
                ))}
              </Flex>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
