import { Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import schneiderChargeImg from '../../../assets/images/se_charge.png';
import TopNav from '../../../common/components/TopNav';
import PageBase from '../../../common/components/PageBase';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

export default function StartSetup() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const [powerCycleInstructions] = useBulkPathSegmentReplace(['power_cycle_instructions']);
  const backUrl = `/site/${siteId}/profile/system-details`;

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={backUrl} />

      <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
        <Text data-testid={'ev-setup-text'} px={8} mt={4}>
          First, make sure that your <strong>Schneider Charge</strong> station has been installed, configured by a
          qualified technician and is connected to the internet.
        </Text>
        <Image src={schneiderChargeImg} alt="Schneider Charge Device" my={4} w="100%" maxW={200} />
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
          <Button
            variant="solid"
            size="md"
            onClick={() => navigate(powerCycleInstructions)}
            rounded={50}
            colorScheme="dusk100"
            minW={180}
            fontWeight={500}
            py={6}
            data-testid={'device-setup-next'}
          >
            Next
          </Button>
          <Button
            my={6}
            data-testid={'skip-device-setup'}
            colorScheme={'customBlue'}
            variant="link"
            width="100%"
            maxWidth={'300px'}
            onClick={() => navigate(backUrl)}
          >
            Skip for Later
          </Button>
        </Flex>
      </Flex>
    </PageBase>
  );
}
