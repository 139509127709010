import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import ProfilePageBase from '../ProfilePageBase';

const SmartSaveSettings = () => {
  return (
    <ProfilePageBase title={'SmartSave Settings'}>
      <Box pt={4}>
        <Text px={4}>COMING IN NEXT PR</Text>
      </Box>
    </ProfilePageBase>
  );
};

export default SmartSaveSettings;
