import React from 'react';
import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import Dialog from '../../../../../../common/components/Dialog';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledLoadFormData } from './types';
import * as yup from 'yup';
import { useTariffFormContext } from '../../../tariff-form-context';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputRightAddon,
  useDisclosure,
} from '@chakra-ui/react';
import { InputGroup } from '@chakra-ui/input';
import CustomAddButton from '../../../rates/common/CustomAddButton';
import { DeleteIcon } from '@chakra-ui/icons';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { MAX_DOLLARS_PER_KWH_VALUE } from '../../../constants';

const EMPTY_CONTROLLED_LOAD_FORM_DATA: ControlledLoadFormData = {
  hasControlledLoad2: false,
  controlledLoad: {
    rate: null,
    hasDailySupplyCharge: false,
    dailySupplyCharge: null,
  },
  controlledLoad2: {
    rate: null,
    hasDailySupplyCharge: false,
    dailySupplyCharge: null,
  },
};

const controlledLoadFormSchema = yup.object().shape({
  controlledLoad: yup
    .object()
    .shape({
      rate: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0)
        .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
        .nullable()
        .test('required-if-cl2-not-supplied', 'This field is required', function (value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const parent: any = (this as unknown as any).from[1].value;

          // Required if CL2 exists but is not supplied
          return value || (parent.hasControlledLoad2 && parent.controlledLoad2.rate);
        }),
      hasDailySupplyCharge: yup.boolean(),
      dailySupplyCharge: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0)
        .nullable(),
    })
    .required(),
  hasControlledLoad2: yup.boolean(),
  controlledLoad2: yup
    .object()
    .nullable()
    .when('hasControlledLoad2', {
      is: (hasControlledLoad2: boolean) => hasControlledLoad2,
      then: yup
        .object()
        .shape({
          rate: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(0)
            .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
            .nullable()
            .test('required-if-cl2-not-supplied', 'This field is required', function (value) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const parent: any = (this as unknown as any).from[1].value;

              if (parent.hasControlledLoad2 && !value) return false;

              // Required if CL is not supplied, or if user selects "has controlled load 2" but enters no value
              return value || parent.controlledLoad.rate;
            }),
          hasDailySupplyCharge: yup.boolean(),
          dailySupplyCharge: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(0)
            .nullable(),
        })
        .required(),
    }),
});

export default function ControlledLoadFormDialog({ isOpen, onClose }: DialogProps) {
  const {
    formData: {
      additionalRatesAndDiscounts: { controlledLoads },
    },
    onUpdateFormData,
  } = useTariffFormContext();
  const {
    watch,
    control,
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ControlledLoadFormData>({
    resolver: yupResolver(controlledLoadFormSchema),
    defaultValues: controlledLoads ?? EMPTY_CONTROLLED_LOAD_FORM_DATA,
  });
  const { isOpen: isAlertDialogOpen, onClose: onCloseAlertDialog, onOpen: onOpenAlertDialog } = useDisclosure();
  const hasControlledLoad2 = watch('hasControlledLoad2');
  const controlledLoadHasDailySupplyCharge = watch('controlledLoad.hasDailySupplyCharge');
  const controlledLoad2HasDailySupplyCharge = watch('controlledLoad2.hasDailySupplyCharge');

  async function handleSubmitControlledLoadForm(controlledLoadsValue: ControlledLoadFormData) {
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, controlledLoads: controlledLoadsValue },
    }));
    onClose();
  }

  function handleClearForm() {
    reset(EMPTY_CONTROLLED_LOAD_FORM_DATA);
    onUpdateFormData((p) => ({
      ...p,
      additionalRatesAndDiscounts: { ...p.additionalRatesAndDiscounts, controlledLoads: null },
    }));
    onClose();
  }

  return (
    <>
      <Dialog showCloseButton title="Controlled Load Rates" isOpen={isOpen} onClose={onClose}>
        <Box
          mx={4}
          as="form"
          data-testid="controlled-load-form"
          onSubmit={handleSubmit(handleSubmitControlledLoadForm)}
        >
          <Heading size="md">Controlled Load</Heading>
          <FormControl mt={2} isInvalid={!!errors?.controlledLoad?.rate}>
            <FormLabel mb={0} mr={0} fontWeight="bold">
              Controlled Load Rate
            </FormLabel>
            <InputGroup>
              <Input
                data-testid="controlled-load-rate-input"
                {...register('controlledLoad.rate')}
                type={'number'}
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                step="0.0000000001"
              />
              <InputRightAddon>$ / kWh</InputRightAddon>
            </InputGroup>
            <FormErrorMessage data-testid="controlled-load-rate-error-message">
              {errors?.controlledLoad?.rate?.message}
            </FormErrorMessage>
          </FormControl>

          <Box>
            <Controller
              name="controlledLoad.hasDailySupplyCharge"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Checkbox
                  data-testid="controlled-load-has-daily-supply-charge"
                  onChange={onChange}
                  ref={ref}
                  mt={3}
                  isChecked={value}
                >
                  Controlled load has a daily supply charge
                </Checkbox>
              )}
            />
          </Box>

          {controlledLoadHasDailySupplyCharge && (
            <FormControl mt={3} isInvalid={!!errors?.controlledLoad?.dailySupplyCharge}>
              <FormLabel mb={0} mr={0} fontWeight="bold">
                Controlled Load Supply Charge
              </FormLabel>
              <InputGroup>
                <Input
                  data-testid="controlled-load-daily-supply-charge-input"
                  {...register('controlledLoad.dailySupplyCharge')}
                  type={'number'}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  step="0.0000000001"
                />
                <InputRightAddon>$ / day</InputRightAddon>
              </InputGroup>
              <FormErrorMessage data-testid="controlled-load-daily-supply-charge-error-message">
                {errors?.controlledLoad?.dailySupplyCharge?.message}
              </FormErrorMessage>
            </FormControl>
          )}

          {hasControlledLoad2 ? (
            <>
              <Flex mt={6} align="center">
                <Heading size="md">Controlled Load 2</Heading>

                <IconButton
                  ml={1}
                  size="sm"
                  onClick={() => setValue(`hasControlledLoad2`, false)}
                  color="customRed.500"
                  variant="ghost"
                  icon={<DeleteIcon />}
                  aria-label={`Delete controlled load 2`}
                  data-testid="delete-controlled-load-2-btn"
                />
              </Flex>
              <FormControl mt={2} isInvalid={!!errors?.controlledLoad2?.rate}>
                <FormLabel mb={0} mr={0} fontWeight="bold">
                  Controlled Load 2 Rate
                </FormLabel>
                <InputGroup>
                  <Input
                    data-testid="controlled-load-2-rate-input"
                    {...register('controlledLoad2.rate')}
                    type={'number'}
                    onWheel={(e) => e.currentTarget.blur()}
                    min={0}
                    step="0.0000000001"
                  />
                  <InputRightAddon>$ / kWh</InputRightAddon>
                </InputGroup>
                <FormErrorMessage data-testid="controlled-load-2-rate-error-message">
                  {errors?.controlledLoad2?.rate?.message}
                </FormErrorMessage>
              </FormControl>

              <Box>
                <Controller
                  name="controlledLoad2.hasDailySupplyCharge"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox
                      data-testid="controlled-load-2-has-daily-supply-charge"
                      onChange={onChange}
                      ref={ref}
                      mt={3}
                      isChecked={value}
                    >
                      Controlled load 2 has a daily supply charge
                    </Checkbox>
                  )}
                />
              </Box>

              {controlledLoad2HasDailySupplyCharge && (
                <FormControl mt={3} isInvalid={!!errors?.controlledLoad2?.dailySupplyCharge}>
                  <FormLabel mb={0} mr={0} fontWeight="bold">
                    Controlled Load Supply Charge
                  </FormLabel>
                  <InputGroup>
                    <Input
                      data-testid="controlled-load-2-daily-supply-charge-input"
                      {...register('controlledLoad2.dailySupplyCharge')}
                      type={'number'}
                      onWheel={(e) => e.currentTarget.blur()}
                      min={0}
                      step="0.0000000001"
                    />
                    <InputRightAddon>$ / day</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage data-testid="controlled-load-2-daily-supply-charge-error-message">
                    {errors?.controlledLoad2?.dailySupplyCharge?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            </>
          ) : (
            <CustomAddButton
              data-testid="add-controlled-load-2-btn"
              mt={4}
              onClick={() => {
                setValue('hasControlledLoad2', true);
              }}
            >
              Add Controlled Load 2
            </CustomAddButton>
          )}

          <Center flexDirection="column" mt={6}>
            <Button
              data-testid="submit-controlled-load-form"
              type="submit"
              w={['75%', '75%', '50%']}
              rounded={20}
              colorScheme="dusk100"
            >
              Add
            </Button>

            <Button
              data-testid="clear-controlled-load-form"
              onClick={onOpenAlertDialog}
              mt={2}
              variant="ghost"
              w={['75%', '75%', '50%']}
              colorScheme={'red'}
            >
              Clear All
            </Button>
          </Center>
        </Box>
      </Dialog>

      <AlertDialogModal
        header="Are you sure you want to clear all controlled loads?"
        subHeader=" "
        isOpen={isAlertDialogOpen}
        onClose={onCloseAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        onConfirm={handleClearForm}
        confirmButtonName="Clear"
      />
    </>
  );
}
