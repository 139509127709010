import { AlwaysOnLoad } from 'clipsal-cortex-types/src/api/api-leak-scanner';
import { ViewportType } from 'clipsal-cortex-utils/src/calculations/viewport-helpers';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';
import { useSelector } from 'react-redux';
import { selectSite } from '../site/siteSlice';
import manualAddressSiteMobileImage from '../../assets/images/manual_address_image_mobile.svg';
import manualAddressSiteTabletImage from '../../assets/images/manual_address_image_tablet.svg';
import manualAddressSiteDesktopImage from '../../assets/images/manual_address_image_desktop.svg';
import manualAddressSiteDesktopLargeImage from '../../assets/images/manual_address_image_desktop_lg.svg';
import { baseApi } from '../../app/services/baseApi';

export const profileApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNearmapImage: build.query<string, { size: ViewportType; siteID: number }>({
      query: ({ size, siteID }) => `/v1/sites/${siteID}/image_url?size=${size}`,
      providesTags: (_, __, { siteID }) => [{ type: 'NearmapImage', id: siteID }],
      transformResponse: async (data: { url: string }, _, { size }) => {
        const url = data.url;

        // check if url returns a valid image
        // if its invalid, then it means its a manual address site
        const imageUrl = await new Promise<string>((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(data.url);
          img.onerror = () => {
            let newSiteImage = manualAddressSiteDesktopLargeImage;
            if (size === 'mobile') newSiteImage = manualAddressSiteMobileImage;
            if (size === 'tablet') newSiteImage = manualAddressSiteTabletImage;
            if (size === 'desktop') newSiteImage = manualAddressSiteDesktopImage;
            resolve(newSiteImage);
          };
        });

        return imageUrl;
      },
    }),
    getAlwaysOnLoads: build.query<AlwaysOnLoad[], void>({
      query: () => `/v1/always_on_loads`,
      providesTags: (_, __) => [{ type: 'AlwaysOnLoads' }],
    }),
  }),
});

export const { useGetNearmapImageQuery, useGetAlwaysOnLoadsQuery } = profileApi;

export const useNearmapImage = () => {
  const site = useSelector(selectSite);
  const { viewportType } = useViewportType();
  const { data } = useGetNearmapImageQuery({ size: viewportType, siteID: site.site_id });
  return data || '';
};

export const useAlwaysOnLoads = () => {
  const results = useGetAlwaysOnLoadsQuery();
  return { ...results, data: results.data ?? [] };
};
