export type EnvironmentType = 'development' | 'production' | 'test' | 'staging';
export type ShorthandEnvironmentType = 'DEV' | 'PROD' | 'TEST';
export let ENV_TYPE = import.meta.env?.MODE === 'production' ? 'PROD' : 'DEV';

if (window.Cypress) {
  ENV_TYPE = 'DEV';
}

// Check local storage for a different env setting
const fromStorage = localStorage.getItem('cortexEnvType');
if (!!fromStorage) {
  ENV_TYPE = fromStorage;
}

export const ENV_TYPE_TO_SHORTHAND: Record<EnvironmentType, ShorthandEnvironmentType> = {
  development: 'DEV',
  staging: 'DEV',
  production: 'PROD',
  test: 'TEST',
};
