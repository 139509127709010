import React, { useMemo } from 'react';
import { Avatar, Box, Flex, HStack, Heading, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from '../user/userSlice';
import { PhoneIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { EmailIcon } from './icons';
import ProfilePageBase from './ProfilePageBase';

export default function AccountDetails() {
  const user = useSelector(selectUser);
  const userFullName = useMemo(() => user.firstName + ' ' + user.lastName, [user]);
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const detailsBorder = useColorModeValue('gray.300', 'black');
  return (
    <ProfilePageBase title={'Account Details'}>
      <Flex py={4} justify={'center'} align={'center'} direction={'column'}>
        <Avatar data-private backgroundColor={'#7DD3C9'} name={userFullName} size={'xl'} />
        <Heading data-private fontSize={18} fontWeight={'bold'} my={3}>
          {userFullName}
        </Heading>
      </Flex>

      <Stack spacing={0} bg={backgroundColor}>
        <HStack borderBottom="1px solid" borderColor={detailsBorder} py={6}>
          <Box px={8}>
            <PhoneIcon w={6} h={6} />
          </Box>
          <VStack align={'flex-start'} spacing={0}>
            <Text fontWeight={'bold'}>Mobile</Text>
            <Text data-private>{user.phone}</Text>
          </VStack>
        </HStack>

        <HStack py={6}>
          <Box px={8}>
            <EmailIcon w={6} h={6} />
          </Box>
          <VStack align={'flex-start'} spacing={0}>
            <Text fontWeight={'bold'}>Email</Text>
            <Text data-private>{user.email}</Text>
          </VStack>
        </HStack>
      </Stack>
    </ProfilePageBase>
  );
}
