import React from 'react';
import { Box, Center, Flex, HStack, Heading, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useReduxDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import { selectDevices, selectSite, updateSiteData } from '../site/siteSlice';
import { DEVICE_TYPE_TO_DISPLAY_DATA, OPERATING_STATE_DATA_MAP } from '../site/system-details/constants';
import {
  WIFI_CONFIGURABLE_METER_MODELS,
  WW_MODEL_TO_CLIPSAL_MODEL_MAP,
} from 'clipsal-cortex-utils/src/constants/common-constants';
import { getConfigurableDeviceRoute } from '../site/system-details/utils';
import { checkIfOperatingStateErrorCanBeIgnored } from '../site/system-details/system-details-helpers';
import ProfilePageBase from './ProfilePageBase';
import { useViewportType } from '../../common/hooks/use-viewport-type';

export const SystemDetailsOutlet = () => {
  return <Outlet />;
};

export default function SystemDetails() {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const site = useSelector(selectSite);
  const devices = useSelector(selectDevices);
  const { isDesktopViewport } = useViewportType();
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const iconProperty = useColorModeValue('iconLight', 'iconDark');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');

  const handleOpenWifiConfigModal = () => {
    dispatch(updateSiteData({ isWifiConfigModalOpen: true }));
  };
  return (
    <ProfilePageBase title={'System Details'} backUrl={`/site/${site.site_id}/profile`}>
      <Box background={backgroundColor} pt={isDesktopViewport ? undefined : 2}>
        {devices
          ? devices.map((device, index) => {
              const isLastDevice = index === devices.length - 1;
              const {
                device_type: deviceType,
                model,
                manufacturer_id: manufacturerId,
                manufacturer_name: manufacturerName,
                operating_state: operatingState,
              } = device;

              // Rename WW models to CX equivalent if they exist in the map, otherwise revert to their existing model name
              const displayModel = WW_MODEL_TO_CLIPSAL_MODEL_MAP[model] ?? model;
              const isWifiConfigurableDevice = WIFI_CONFIGURABLE_METER_MODELS.includes(model);
              const isWifiConfigurableMeter = isWifiConfigurableDevice && deviceType === 'METER';
              const configurationPath = getConfigurableDeviceRoute(manufacturerId, deviceType);
              const isConfigurableDevice = isWifiConfigurableMeter || !!configurationPath;

              const canIgnoreError = checkIfOperatingStateErrorCanBeIgnored(device);
              const containerProps = isConfigurableDevice
                ? {
                    onClick: isWifiConfigurableMeter
                      ? handleOpenWifiConfigModal
                      : () => navigate(`/site/${site.site_id}/profile/system-details/${configurationPath}`),
                    cursor: 'pointer',
                    _hover: { bg: hoverColor },
                  }
                : {};

              return (
                <HStack
                  key={device.id}
                  data-testid={`device-${index}`}
                  {...containerProps}
                  py={6}
                  px={6}
                  borderBottom={isLastDevice ? 'none' : '1px solid'}
                  borderColor={borderColor}
                >
                  <Center>
                    <Image src={DEVICE_TYPE_TO_DISPLAY_DATA[deviceType][iconProperty]} alt={'Device icon'} mr={3} />
                    <Flex justify={'center'} direction={'column'}>
                      <Heading size={'sm'}>{DEVICE_TYPE_TO_DISPLAY_DATA[deviceType].title}</Heading>
                      <Text data-testid="manufacturer-model-name" fontSize={'sm'} color={'textGrey.500'}>
                        {manufacturerName} {displayModel}
                      </Text>
                      {!canIgnoreError && operatingState !== null && (
                        <Text fontSize={'sm'} color={OPERATING_STATE_DATA_MAP[operatingState].color}>
                          {OPERATING_STATE_DATA_MAP[operatingState].text}
                        </Text>
                      )}
                    </Flex>
                  </Center>

                  {isConfigurableDevice && (
                    <ChevronRightIcon data-testid="configure-wifi-button" ml="auto" my="auto" w={6} h={6} />
                  )}
                </HStack>
              );
            })
          : 'No devices'}
      </Box>
    </ProfilePageBase>
  );
}
