import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface Profile {
  isExperimentalFeaturesModalOpen: boolean;
}

export type ProfileReducers = {
  toggleExperimentalFeaturesModal: (state: Profile, action: PayloadAction<boolean>) => void;
};

const initialState = {
  isExperimentalFeaturesModalOpen: false,
};

export const profileSlice = createSlice<Profile, ProfileReducers>({
  name: 'profile',
  initialState,
  reducers: {
    toggleExperimentalFeaturesModal: (state, action) => {
      return {
        ...state,
        isExperimentalFeaturesModalOpen: action.payload,
      };
    },
  },
});

export const { toggleExperimentalFeaturesModal } = profileSlice.actions;

export const selectIsExperimentalFeaturesModalOpen = (state: RootState) => {
  return state.profile.isExperimentalFeaturesModalOpen;
};

export default profileSlice.reducer;
