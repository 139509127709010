import React from 'react';
import { BOTTOM_NAV_HEIGHT, PAGE_SPACING_PER_VIEWPORT } from '../constants';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import useViewportType from '../hooks/use-viewport-type';
import { Box, BoxProps } from '@chakra-ui/react';

export default function PageBase({ children, ...rest }: BoxProps) {
  const { isDesktopViewport } = useViewportType();
  const isMobileViewport = !isDesktopViewport;

  return (
    <Box
      mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : undefined}
      px={PAGE_SPACING_PER_VIEWPORT}
      mt={isMobileViewport ? TOP_NAV_SPACING_AFFORDANCE : 3}
      {...rest}
    >
      {children}
    </Box>
  );
}
