import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BasicDetailsFormData } from './types';
import { chakraComponents, Select } from 'chakra-react-select';
import { SearchIcon } from '@chakra-ui/icons';
import { useRetailers } from '../tariffApi';
import { REAL_TIME_RETAILER_IDS } from '../../../../common/constants';
import { EMPTY_RETAILER_OPTION } from '../constants';

export default function RetailerSelect() {
  const { control, getValues, setValue } = useFormContext<BasicDetailsFormData>();
  const { retailers, isLoading } = useRetailers();
  const selectOptions = useMemo(() => {
    const retailerList = retailers.map((retailer) => ({ label: retailer.name, value: retailer.id }));
    return [EMPTY_RETAILER_OPTION, ...retailerList];
  }, [retailers]);

  return (
    <Controller
      control={control}
      name="retailer"
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Select
          ref={ref}
          onChange={(e) => {
            const existingTariffTypeValue = getValues('tariffType');
            // The tariff type select component doesn't support choosing real-time rates, because there are only some
            // specific retailers which support this structure.
            // If a user has already selected a real-time tariff and attempts to select a different retailer which does
            // not support real-time tariffs, automatically revert the tariff type.
            if (
              existingTariffTypeValue === 'REAL_TIME' &&
              REAL_TIME_RETAILER_IDS.includes(value.value) &&
              e &&
              !REAL_TIME_RETAILER_IDS.includes(e.value)
            ) {
              setValue('tariffType', 'FLAT');
            }
            onChange(e);
          }}
          isLoading={isLoading}
          placeholder={'Select your retailer..'}
          onBlur={onBlur}
          // Not specifying portal target can cause the menu to become hidden.
          menuPortalTarget={document.body}
          options={selectOptions}
          value={value}
          useBasicStyles
          components={{
            DropdownIndicator: (props) => (
              <chakraComponents.DropdownIndicator {...props}>
                <SearchIcon />
              </chakraComponents.DropdownIndicator>
            ),
          }}
        />
      )}
    />
  );
}
