import { Bill } from 'clipsal-cortex-types/src/api/api-bills';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, Center, Flex, Grid, Heading, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import { formatNiceDateFromString } from 'clipsal-cortex-utils/src/formatting/formatting';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { calculateDifferenceBetweenDates } from 'clipsal-cortex-utils/src/calculations/date-utils';
import PDFViewerModal from '../../../common/components/PDFViewer';
import { get } from '../../../common/api/api-helpers';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { RetailerImage } from '../RetailerImage';

interface PaginatedBillsProps {
  bills: Bill[];
}

type BillPDFState = { pdfBase64: string | null; title: string | null };

const initialPDFState = { pdfBase64: null, title: null };

export function PaginatedBillsList({ bills }: Readonly<PaginatedBillsProps>) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [{ pdfBase64, title }, setPDFBase64] = useState<BillPDFState>(initialPDFState);
  const pages = buildPages(bills);
  const buttonHoverColor = useColorModeValue('rgba(0, 0, 0, 0.04)', 'black');
  const buttonBackgroundColors = useColorModeValue(
    {
      selected: 'black',
      unselected: 'white',
    },
    {
      selected: 'white',
      unselected: 'gray.900',
    }
  );
  const buttonTextColors = useColorModeValue(
    {
      selected: 'white',
      unselected: 'black',
    },
    {
      selected: 'black',
      unselected: 'white',
    }
  );

  return (
    <Flex height={'90%'} direction={'column'}>
      <Box my={3} data-testid="bill-list">
        {getVisibleBills(bills, pages, currentPageNumber).map((bill, i) => {
          const [isNewYear, billEndYear] = checkIfBillIsInNewYear(bills, bill);

          return (
            <BillListItem
              key={bill.id}
              billIndex={i}
              billEndYear={billEndYear}
              isNewYear={isNewYear}
              bill={bill}
              onSetPDFBase64={setPDFBase64}
            />
          );
        })}
      </Box>

      {/* Bottom pagination panel */}
      <Flex height={'100%'} justifySelf={'flex-end'} align={'flex-end'} justify={'center'}>
        {pages.length > 1 && (
          <IconButton
            onClick={() => {
              setCurrentPageNumber(currentPageNumber - 1);
            }}
            isDisabled={currentPageNumber === 1}
            mr={0.5}
            size={'sm'}
            aria-label={'Move left'}
            variant={'ghost'}
            icon={<ChevronLeftIcon />}
          />
        )}

        {pages.map((page, i) => {
          return (
            <Box
              mx={0.5}
              rounded={10}
              px={2}
              py={1}
              as={'button'}
              onClick={() => {
                setCurrentPageNumber(page.pageNum);
              }}
              _hover={{ background: page.pageNum === currentPageNumber ? 'grey' : buttonHoverColor }}
              key={`page-num-btn-${i}`}
              color={page.pageNum === currentPageNumber ? buttonTextColors.selected : buttonTextColors.unselected}
              background={
                page.pageNum === currentPageNumber ? buttonBackgroundColors.selected : buttonBackgroundColors.unselected
              }
            >
              {page.pageNum}
            </Box>
          );
        })}

        {pages.length > 1 && (
          <IconButton
            onClick={() => {
              setCurrentPageNumber(currentPageNumber + 1);
            }}
            isDisabled={currentPageNumber === pages.length}
            ml={0.5}
            size={'sm'}
            aria-label={'Move right'}
            variant={'ghost'}
            icon={<ChevronRightIcon />}
          />
        )}
      </Flex>

      {pdfBase64 !== null && (
        <PDFViewerModal
          fileName={title ?? 'bill_pdf'}
          contents={pdfBase64}
          onClose={() => {
            setPDFBase64(initialPDFState);
          }}
          isOpen={!!pdfBase64}
        />
      )}
    </Flex>
  );
}

type BillListItemProps = {
  billIndex: number;
  isNewYear: boolean;
  billEndYear: number;
  bill: Bill;
  onSetPDFBase64: Dispatch<SetStateAction<BillPDFState>>;
};

function BillListItem({ bill, billEndYear, billIndex, isNewYear, onSetPDFBase64 }: Readonly<BillListItemProps>) {
  const borderColor = useColorModeValue('darkModeBorderColor.500', 'textGrey.500');
  const fetchBillBase64 = (bill: Bill) => async () => {
    // Fetch bill data, pass to state in order to open dialog.
    const { base64_file: pdfBase64 } = await get<{ base64_file: string; file_type: string }>(`/v1/bills/${bill.id}`);

    onSetPDFBase64({ pdfBase64, title: `${bill.retailer}_${bill.start_date}_${bill.end_date}_pdf_bill` });
  };

  return (
    <>
      {isNewYear && (
        <Heading
          borderBottom={billIndex !== 0 ? `1px solid` : undefined}
          borderColor={borderColor}
          mt={1}
          pb={1}
          size={'sm'}
        >
          {billEndYear}
        </Heading>
      )}
      <Box
        onClick={fetchBillBase64(bill)}
        width={'100%'}
        as={'button'}
        cursor={'pointer'}
        _hover={{ background: 'rgba(0, 0, 0, 0.04)' }}
        className="eachBill"
        data-testid={`each-bill-${billIndex}`}
      >
        <Grid
          py={4}
          borderTop={billIndex === 0 ? '1px solid' : undefined}
          borderBottom="1px solid"
          borderColor={borderColor}
          templateColumns={'50px 1fr'}
        >
          {/* NOTE: wrapping the actual `Image` in a `Skeleton` causes skeletons to be full height, so use
                            `SkeletonCircle` instead. */}
          <Center>
            <RetailerImage id={bill.retailer_id} size={'md'} name={bill.retailer} />
          </Center>
          <Flex justify={'space-between'}>
            <Flex ml={2} justify={'center'} align={'flex-start'} direction={'column'}>
              <Heading size={'sm'}>{formatNiceDateFromString(bill.end_date)}</Heading>
              <Text fontSize={'sm'}>{formatNiceDateFromString(bill.start_date)}</Text>
            </Flex>

            <Grid templateColumns={'1fr 30px'}>
              <Flex textAlign={'right'} direction={'column'}>
                <Text fontWeight={'bold'}>{formatDollarsCents(bill.total_amount)}</Text>
                <Text color={'textGrey.500'} fontSize={'sm'}>
                  for&nbsp;
                  {calculateDifferenceBetweenDates(
                    new Date(bill.start_date.replace(/-/g, '/')),
                    new Date(bill.end_date.replace(/-/g, '/'))
                  )}
                  &nbsp;days
                </Text>
              </Flex>
              <ChevronRightIcon w={6} h={6} color={'rgba(0, 0, 0, 0.25)'} alignSelf={'center'} />
            </Grid>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export function NoBills({ onOpenUploadBillDialog }: { onOpenUploadBillDialog: () => void }) {
  return (
    <Center flexDirection={'column'}>
      <Heading color={'noBillsReceivedHeading.500'} mb={2} mt={4} size={'lg'}>
        No Bills Received
      </Heading>
      <Button
        className={'tour-bills-consumption-only-step'}
        isDisabled={IS_DEMO_LOGIN}
        onClick={onOpenUploadBillDialog}
        mt={2}
        mb={4}
        rounded={20}
        colorScheme="dusk100"
      >
        Upload Bill
      </Button>
    </Center>
  );
}

const BILLS_PER_PAGE = 3;

type Page = {
  pageNum: number;
  indices: [number, number];
};

/**
 * Constructs an array `Page`-shaped objects, based on the number of provided `Bill` objects.
 *
 * @param bills - The bills to paginate.
 */
function buildPages(bills: Bill[]) {
  return bills.reduce<Page[]>((pages, bill, i) => {
    if (i === 0) {
      pages.push({
        pageNum: 1,
        indices: [0, 0],
      });
    } else if (i % BILLS_PER_PAGE === 0) {
      pages.push({
        pageNum: i / BILLS_PER_PAGE + 1,
        indices: [i, i + 3],
      });
    } else {
      pages[pages.length - 1].indices[1] = i;
    }

    return pages;
  }, []);
}

/**
 * Discerns which `Bill` objets are currently visible, according to the current page number.
 *
 * @param bills - The full `Bill`s array.
 * @param pages - All pages of bills.
 * @param currentPageNumber - The current page number.
 */
function getVisibleBills(bills: Bill[], pages: Page[], currentPageNumber: number) {
  let visibleBills: Bill[] = [];

  if (bills.length) {
    const currentPage = pages.find((p) => p.pageNum === currentPageNumber);

    if (currentPage) {
      const [startIndex, endIndex] = currentPage.indices;

      visibleBills = bills.slice(startIndex, endIndex + 1);
    }
  }

  return visibleBills;
}

/**
 * Checks if the specified current bill is in a new billing year.
 *
 * @param bills - All bills, as an array.
 * @param currentBill - The current `Bill` object being mapped.
 * @returns A tuple where indices:
 *            [0] = Whether the bill is in a new year
 *            [1] = The bill's end year, as a number.
 */
function checkIfBillIsInNewYear(bills: Bill[], currentBill: Bill): [boolean, number] {
  const billIndex = bills.findIndex((bill) => bill === currentBill);
  const nextBill = bills[billIndex - 1];
  const billEndYear = new Date(currentBill.end_date.replace(/-/g, '/')).getFullYear();
  let isNewYear = billIndex === 0;

  // Make sure there's a next bill
  if (nextBill) {
    const nextBillEndYear = new Date(nextBill.end_date.replace(/-/g, '/')).getFullYear();

    // We're at a new year if the current bill's end year is lower than the end year of the next bill
    isNewYear = billEndYear < nextBillEndYear;
  }

  return [isNewYear, billEndYear];
}
