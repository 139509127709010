export const TESLA_CLIENT_ID = 'cf6357813b13-4b51-81d3-30bfecaad9cf';
export const TESLA_SCOPES = [
  'openid',
  'email',
  'offline_access',
  'vehicle_device_data',
  'vehicle_charging_cmds',
  'energy_device_data',
  'energy_cmds',
];
