import energyMastersBranding from './../../assets/branding/energy_masters.json';
import defaultBranding from './../../assets/branding/default.json';
import { ENERGY_MASTERS_TENANT_ID } from 'clipsal-cortex-utils/src/constants/common-constants';
import { ChakraTheme } from '@chakra-ui/theme';
import { DeepPartial } from '@chakra-ui/theme-utils/dist/extend-theme';

export const TENANT_ID_TO_BRANDING_MAP: Record<number, Record<string, DeepPartial<ChakraTheme>>> = {
  [ENERGY_MASTERS_TENANT_ID]: energyMastersBranding as Record<string, DeepPartial<ChakraTheme>>,
};

export const getThemeForTenant = (tenantID: number): Record<string, DeepPartial<ChakraTheme>> => {
  // @TODO: Remove this when we have sites that are from Energy Masters tenant
  if (localStorage.getItem('enableEnergyMastersBranding') === 'true')
    return energyMastersBranding as Record<string, DeepPartial<ChakraTheme>>;

  const tenantHasCustomBranding = tenantID in TENANT_ID_TO_BRANDING_MAP;
  return tenantHasCustomBranding
    ? TENANT_ID_TO_BRANDING_MAP[tenantID]
    : (defaultBranding as Record<string, DeepPartial<ChakraTheme>>);
};
