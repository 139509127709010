import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getDifferenceBetweenCosts, rpcDisplayLabelTypeToIcon } from './helpers';
import { useSelector } from 'react-redux';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import { BOTTOM_NAV_HEIGHT, PAGE_SPACING_PER_VIEWPORT } from '../../../../common/constants';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { ImInfo } from 'react-icons/im';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import infoGreyImg from '../../../../assets/images/info_grey.svg';
import openLinkInCortexApp from '../../../../utils/native/browser';
import MobileTopNav from '../../../../common/components/MobileTopNav';
import { useGetSiteRetailPlanCheckQuery } from '../../billsApi';
import { selectSite } from '../../../site/siteSlice';
import { useTariffs } from '../../tariffs/tariffApi';
import { RetailerImage } from '../../RetailerImage';

export default function MobileViewPlanDetail() {
  const { planID } = useParams<{ planID: string }>();
  const site = useSelector(selectSite);
  const { data: rpcData, isLoading: isRPCLoading } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const { plans, current_bill_data: currentBillData } = rpcData!;
  const { isLoading: isTariffsDataLoading } = useTariffs({ offset: 0, limit: 5 });
  const isLoaded = !isTariffsDataLoading && !isRPCLoading;
  const plan = plans?.[Number(planID)];
  const retailerID = plan?.tariff.retailer.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expandedIndices, setExpandedIndices] = useState<[number, number][]>([]);

  if (!isLoaded || !plan) return <CenteredLoader />;

  return (
    <>
      <MobileTopNav title={plan.tariff.retailer.name} backURL={`/site/${site.site_id}/bills/compare_plans/view_all`} />

      <Card
        mt={TOP_NAV_SPACING_AFFORDANCE}
        mb={BOTTOM_NAV_HEIGHT}
        mx={PAGE_SPACING_PER_VIEWPORT}
        data-testid="plan-details"
      >
        <Flex direction={'column'} align={'center'}>
          <RetailerImage id={retailerID} skip={!retailerID} size={'lg'} name={plan.tariff.retailer.name} />
          <Heading mt={2} size={'md'}>
            {plan.tariff.retailer.name}
          </Heading>
          <Text mb={3}>{plan.tariff.name}</Text>

          <Text mb={2} fontSize={'sm'}>
            You could've paid
          </Text>
          <Heading size={'xl'}>{formatDollarsCents(plan.cost)}</Heading>
          <Box mb={2}>{getDifferenceBetweenCosts(currentBillData.cost, plan.cost)}</Box>

          {plan.tariff.display_labels.map((displayLabel, i) => (
            <Flex fontSize={'sm'} align={'center'} key={`rpc-display-label-${i}`}>
              {rpcDisplayLabelTypeToIcon[displayLabel.type]} {displayLabel.value}
            </Flex>
          ))}

          {
            <Grid
              py={3}
              my={3}
              width={'100%'}
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor={'dusk005.500'}
              _dark={{
                borderColor: 'textGrey.500',
              }}
              templateColumns={'1fr 20px'}
            >
              <Text fontSize={'sm'}>
                {!!plan.default_offer_message
                  ? plan.default_offer_message
                  : 'Something went wrong loading the DMO for this plan.'}
              </Text>

              <Box onClick={onOpen} as={'button'}>
                <Box as={ImInfo} color={'customBlue.500'} size="18px" />
              </Box>
            </Grid>
          }
        </Flex>

        {/* Display data */}
        {plan.tariff.display_section.map((displaySection, displaySectionIndex) => (
          <Box
            borderBottom="1px solid"
            borderColor={'dusk005.500'}
            _dark={{
              borderColor: 'textGrey.500',
            }}
            fontSize={'sm'}
            mb={3}
            key={`mobile-display-section-${displaySectionIndex}`}
          >
            <Heading mb={2} size={'xs'}>
              {displaySection.name}
            </Heading>

            {displaySection.values.map(({ name, value, condition }, displayValueIndex) => {
              let isValueTruncated = value.length > 75;

              if (expandedIndices.find(([i, j]) => i === displaySectionIndex && j === displayValueIndex)) {
                isValueTruncated = false;
              }

              const valueText = isValueTruncated ? value.slice(0, 75) + '...' : value;

              return (
                <SimpleGrid columns={2} mb={4} key={`mobile-display-section-${displayValueIndex}`}>
                  <Text>{name}</Text>

                  <Box textAlign={'end'} justifySelf={'end'}>
                    <Text mb={condition ? 2 : undefined}>{valueText}</Text>
                    {isValueTruncated && !expandedIndices[displaySectionIndex]?.includes(displayValueIndex) && (
                      <Box
                        onClick={() => {
                          const position: [number, number] = [displaySectionIndex, displayValueIndex];

                          setExpandedIndices([...expandedIndices, position]);
                        }}
                        as={'button'}
                        fontSize={'sm'}
                        fontWeight={'bolder'}
                        color={'customBlue.500'}
                      >
                        Read more
                      </Box>
                    )}
                    {condition && <Text>{condition}</Text>}
                  </Box>
                </SimpleGrid>
              );
            })}
          </Box>
        ))}

        {plan.tariff.url && (
          <Flex direction={'column'} align={'center'}>
            <Button
              onClick={() => {
                openLinkInCortexApp(plan.tariff.url as string);
              }}
              size={'sm'}
              my={1}
              variant={'ghost'}
              color={'customBlue.500'}
            >
              Read the fine print
            </Button>
          </Flex>
        )}
      </Card>

      <Modal motionPreset={'slideInBottom'} scrollBehavior={'inside'} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={20}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <DMOInfoContents />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

export function DMOInfoContents() {
  return (
    <Box textAlign={'center'}>
      <Flex mb={2} justify={'center'} align={'center'}>
        <Image width={'50%'} src={infoGreyImg} alt={'Info sign'} />
      </Flex>
      <Heading mb={3} size={'md'}>
        Default Market Offer (DMO)
      </Heading>
      <Text fontSize={'md'}>
        The amount estimates above are based on average household electricity usage benchmarks set by energy industry
        regulators in NSW, QLD, SA and VIC. For an average household in the Endeavour network using 7400kWh/year on a
        Single Rate with Control Load tariff. Amounts do not include any concessions, rebates or green energy charges.
        Amounts include all other benefits (including discounts) that you're eligible for, and GST (unless otherwise
        stated). Your actual bill may vary depending on your usage.
      </Text>
    </Box>
  );
}
