import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { PlanComparison } from './ComparePlans';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { BOTTOM_NAV_HEIGHT, PAGE_SPACING_PER_VIEWPORT } from '../../../../common/constants';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import MobileTopNav from '../../../../common/components/MobileTopNav';
import { useTariffs } from '../../tariffs/tariffApi';
import { useGetSiteRetailPlanCheckQuery } from '../../billsApi';
import { selectSite } from '../../../site/siteSlice';
import { useSelector } from 'react-redux';
import { BillsOutletContext } from '../../SiteBills';

export default function MobileViewPlans() {
  const { isParentLoaded } = useOutletContext<BillsOutletContext>();
  const site = useSelector(selectSite);
  const { isLoading: isTariffsDataLoading } = useTariffs({ offset: 0, limit: 5 });
  const { isLoading: isRPCLoading } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const isLoaded = !isTariffsDataLoading && !isRPCLoading;

  if (!isParentLoaded || !isLoaded) return <CenteredLoader />;

  return (
    <Box mb={BOTTOM_NAV_HEIGHT} mx={PAGE_SPACING_PER_VIEWPORT}>
      <MobileTopNav title={'All Plans'} backURL={`/site/${site.site_id}/bills/compare_plans/`} />

      <Box mt={TOP_NAV_SPACING_AFFORDANCE}>
        <PlanComparison isAlwaysExpanded />
      </Box>
    </Box>
  );
}
