import { useDispatch } from 'react-redux';
import {
  Action,
  CombinedState,
  combineReducers,
  configureStore,
  PayloadAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import sitesReducer from '../features/sites/sitesSlice';
import siteReducer from '../features/site/siteSlice';
import profileReducer from '../features/profile/profileSlice';
import savingsReducer from '../features/savings/savingsSlice';
import unmeasuredAppliancesSlice from '../features/live/unmeasuredAppliancesSlice';
import schedulerReducer from '../features/scheduler/schedulerSlice';
import schneiderChargeSetupReducer from '../features/ev-chargers/schneider-charge/schneiderChargeSetupSlice';
import evChargerReducer from '../features/ev-chargers/evChargerSlice';
import thunk from 'redux-thunk';
import { baseApi } from './services/baseApi';

const rootReducer = combineReducers({
  sites: sitesReducer,
  savings: savingsReducer,
  user: userReducer,
  site: siteReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  profile: profileReducer,
  unmeasuredAppliances: unmeasuredAppliancesSlice,
  scheduler: schedulerReducer,
  schneiderChargeSetup: schneiderChargeSetupReducer,
  evCharger: evChargerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Wraps our root reducer, allowing store de-hydration from the top-level.
 *
 * @TODO: figure out why we can't use `RootState` generic type on `CombinedState`.
 *
 * @param state - The root state
 * @param action - The action sent.
 */
export const resettableRootReducer = (state: CombinedState<RootState> | undefined, action: PayloadAction<unknown>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  // Disable default middleware in dev mode due to slowdown. Remove this property to re-enable.
  middleware: [thunk, baseApi.middleware],
});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type ReduxDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
