import {
  LineItemModifier,
  SiteTariffToSave,
  TariffRate,
  TariffSeason,
  TOUPeriod,
  TOURateType,
} from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { TariffFormDataState } from './tariff-form-context';
import { capitalizeFirst, formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import {
  TimeOfUse,
  TimeOfUsePeriodSelectOption,
  TOURate,
  TOURateType as FormTOURateType,
  TOUTariffFormData,
  TOUTime,
} from './rates/time-of-use/types';
import { TieredTariffFormData } from './rates/tiered/types';
import { FlatTariffFormData } from './rates/flat/types';
import { GST_VALUE_DIVISOR, TARIFF_TYPE_TO_FORM_DATA_PROPERTY } from './constants';
import { ControlledLoadFormData } from './review/additional-rates-and-discounts/controlled-loads/types';
import { UI_DISCOUNT_OPTION_TO_DISCOUNT_TYPE } from './review/additional-rates-and-discounts/discounts/constants';
import { Discount } from './review/additional-rates-and-discounts/discounts/types';
import { convertTimeToDate } from './rates/time-of-use/utils';
import { store } from '../../../app/store';
import { RealTimeTariffFormData } from './rates/real-time/types';
import { Season } from './rates/types';

type RateFormProperty = 'touRates' | 'flatRates' | 'tieredRates';

type TOUCoverageData = { fromTime: Date; toTime: Date };

type TOUCoverageMeasurementPeriodType = Exclude<TimeOfUsePeriodSelectOption['value'], 'EVERYDAY'>;

type TOUCoverageMeasurementPeriodTypeLabel = Exclude<TimeOfUsePeriodSelectOption['label'], 'Everyday'>;

const MIDNIGHT = new Date(2021, 0, 1, 0, 0, 0, 0);

const MIDNIGHT_TOU_TIME: TOUTime = {
  minutes: 0,
  hours: 12,
  amOrPm: 'AM',
};

const FORM_RATE_TYPE_TO_API_RATE_TYPE: Record<FormTOURateType, Exclude<TOURateType, 'SINGLE'>> = {
  PEAK: 'PEAK',
  'OFF-PEAK': 'OFF_PEAK',
  SHOULDER: 'PARTIAL_PEAK',
};

export function mapFormValuesToAPI(tariffFormData: TariffFormDataState, siteTariffId?: number): SiteTariffToSave {
  const { basicDetails } = tariffFormData;
  const site = store.getState().site;

  if (!basicDetails) throw new Error(`No basic details supplied!`);
  const { startDate, retailer, tariffType } = basicDetails;

  const siteTariff: SiteTariffToSave = {
    tariff_effective_date: formatDate(startDate),
    tariff: {
      plan_name: retailer.label,
      seasons: mapSeasonsToAPI(tariffFormData),
      rates: mapRatesToAPI(tariffFormData),
      retailer_id: retailer.value || null,
      line_item_modifiers: mapDiscountsToAPI(tariffFormData),
      holiday_country: 'AU', // @TODO: update when we support other countries
      holiday_subdiv: site.state,
      tariff_type: tariffType,
    },
  };

  if (siteTariffId) {
    siteTariff.id = siteTariffId;
  }

  return siteTariff;
}

function mapSeasonsToAPI(tariffFormData: TariffFormDataState): TariffSeason[] | undefined {
  if (!tariffFormData.basicDetails) throw new Error(`No basic details in form!`);
  // NOTE: Real-time rates have no season data.
  if (tariffFormData.basicDetails.tariffType === 'REAL_TIME') return undefined;
  const formProperty = TARIFF_TYPE_TO_FORM_DATA_PROPERTY[tariffFormData.basicDetails.tariffType];

  return tariffFormData?.[formProperty]!.seasons.map((formSeason, seasonIndex) => {
    const { toDate, toMonth } = adjustSeasonToDate(formSeason);

    return {
      name: formSeason.name || `Season ${seasonIndex + 1}`,
      season_index: seasonIndex + 1,
      from_month: formSeason.fromMonth + 1,
      from_date: formSeason.fromDate,
      to_month: toMonth + 1,
      to_date: toDate,
    };
  });
}

function mapRatesToAPI(tariffFormData: TariffFormDataState): TariffRate[] {
  if (!tariffFormData.basicDetails) throw new Error(`No basic details in form!`);
  const allRates: TariffRate[] = [];

  switch (tariffFormData.basicDetails.tariffType) {
    case 'TOU':
      if (!tariffFormData.touRates)
        throw new Error(`Tried to map a TOU tariff's rates to the API, but there were no rates to map!`);
      allRates.push(...mapTOURatesToAPI(tariffFormData.touRates));
      break;
    case 'TIERED':
      if (!tariffFormData.tieredRates)
        throw new Error(`Tried to map a tiered tariff's rates to the API, but there were no rates to map!`);
      allRates.push(...mapTieredRatesToAPI(tariffFormData.tieredRates));
      break;
    case 'FLAT':
      if (!tariffFormData.flatRates)
        throw new Error(`Tried to map a flat tariff's rates to the API, but there were no rates to map!`);
      allRates.push(...mapFlatRatesToAPI(tariffFormData.flatRates));
      break;
    case 'REAL_TIME':
      if (!tariffFormData.realTimeRates)
        throw new Error(`Tried to map a flat tariff's rates to the API, but there were no rates to map!`);
      allRates.push(...mapRealTimeRatesToAPI(tariffFormData.realTimeRates));
  }

  // The following rates do not apply for real-time tariffs (e.g. Amber).
  if (tariffFormData.basicDetails.tariffType !== 'REAL_TIME') {
    const formProperty = TARIFF_TYPE_TO_FORM_DATA_PROPERTY[tariffFormData.basicDetails.tariffType];
    const ratesAreInclusiveOfGST = tariffFormData[formProperty]?.ratesAreInclusiveOfGST;

    let rate = tariffFormData.basicDetails.dailySupplyCharge;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    // Add the supply charge from basic details
    allRates.push({
      charge_period: 'DAILY' as const,
      charge_type: 'FIXED_PRICE' as const,
      charge_class: 'DISTRIBUTION' as const,
      rate_bands: [{ rate, sequence_number: 1 }],
    });

    allRates.push(...mapAdditionalRatesToAPI(tariffFormData));
  }

  return allRates;
}

function mapTOURatesToAPI(touFormData: TOUTariffFormData): TariffRate[] {
  const { ratesAreInclusiveOfGST, seasons } = touFormData;

  return seasons.flatMap((season, seasonIndex) => {
    const formRatesForSeason: TOURate[] = [];
    const allRates = [];
    if (season?.peakRate) allRates.push(season.peakRate);
    allRates.push(...season.shoulderRates);
    if (season?.offPeakRate) allRates.push(season.offPeakRate);
    const explicitlyApplicableSeasonRates = allRates.filter((rate) => !rate.appliesAtAllOtherTimes);
    formRatesForSeason.push(...explicitlyApplicableSeasonRates);

    // If any rate is applicable at all other times, fill the gaps.
    const rateWhichAppliesAtAllOtherTimes = allRates.find((rate) => rate.appliesAtAllOtherTimes);
    if (rateWhichAppliesAtAllOtherTimes) {
      formRatesForSeason.push(
        createRateWhichAppliesAtAllOtherTimes(explicitlyApplicableSeasonRates, rateWhichAppliesAtAllOtherTimes)
      );
    }

    return formRatesForSeason.map((rate) => {
      let rateValue = rate.tiers[0].rate as number;
      if (ratesAreInclusiveOfGST) rateValue = getValueBeforeGST(rateValue);

      return {
        season_index: seasonIndex + 1,
        charge_type: 'CONSUMPTION_BASED' as const,
        charge_class: 'SUPPLY' as const,
        transaction_type: 'BUY_IMPORT' as const,
        time_of_use: {
          tou_name: `${capitalizeFirst(rate.type.toLowerCase())} Consumption`,
          tou_rate_type: FORM_RATE_TYPE_TO_API_RATE_TYPE[rate.type],
          periods: mapPeriodsFromRate(rate),
        },
        rate_bands: [{ rate: rateValue, sequence_number: 1 }],
      };
    });
  });
}

function mapPeriodsFromRate(rate: TOURate): TOUPeriod[] {
  return rate.timesOfUse.flatMap((timeOfUse) => {
    const periods: TOUPeriod[] = [];

    const baseTimeFrame = {
      from_hour: getHourValue(timeOfUse.fromTime, 'from'),
      to_hour: getHourValue(timeOfUse.toTime, 'to'),
      from_minute: timeOfUse.fromTime.minutes,
      to_minute: timeOfUse.toTime.minutes,
    };

    if (timeOfUse.applicablePeriods.find((period) => period.value === 'WEEKENDS')) {
      periods.push({
        days: ['SAT', 'SUN'],
        public_holiday: false,
        ...baseTimeFrame,
      });
    }

    if (timeOfUse.applicablePeriods.find((period) => period.value === 'WEEKDAYS')) {
      periods.push({
        days: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
        public_holiday: false,
        ...baseTimeFrame,
      });
    }

    if (timeOfUse.applicablePeriods.find((period) => period.value === 'EVERYDAY')) {
      periods.push({
        days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
        public_holiday: false,
        ...baseTimeFrame,
      });
    }

    if (timeOfUse.applicablePeriods.find((period) => period.value === 'PUBLIC_HOLIDAYS')) {
      periods.push({
        days: [],
        ...baseTimeFrame,
        public_holiday: true,
      });
    }

    return periods;
  });
}

function mapTieredRatesToAPI(tieredFormData: TieredTariffFormData): TariffRate[] {
  const { ratesAreInclusiveOfGST, seasons } = tieredFormData;

  return seasons.map((season, seasonIndex) => {
    return {
      season_index: seasonIndex + 1,
      charge_type: 'CONSUMPTION_BASED' as const,
      charge_class: 'SUPPLY' as const,
      transaction_type: 'BUY_IMPORT' as const,
      charge_period: season.chargePeriod.value,
      rate_bands: season.tiers.map((tier, tierIndex) => {
        let rate = tier.rate as number;
        if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);
        // Only the last tier has no consumption limit
        const hasConsumptionLimit = tierIndex !== season.tiers.length - 1;

        return {
          rate: rate,
          sequence_number: tierIndex + 1, // NOTE: These are always ordered by rate ASC due to form validation
          has_consumption_limit: hasConsumptionLimit,
          consumption_upper_limit: hasConsumptionLimit ? tier.upperLimitKWh : undefined,
        };
      }),
    };
  });
}

function mapRealTimeRatesToAPI(realTimeRateData: RealTimeTariffFormData): TariffRate[] {
  const realTimeRates = realTimeRateData.seasons[0];
  const rates: TariffRate[] = [
    {
      charge_period: 'DAILY' as const,
      charge_type: 'FIXED_PRICE' as const,
      charge_class: 'DISTRIBUTION' as const,
      rate_bands: [{ rate: realTimeRates.dailySupplyCharge, sequence_number: 1 }],
    },
    {
      charge_type: 'REAL_TIME' as const,
      charge_class: 'SUPPLY' as const,
      transaction_type: 'BUY_IMPORT' as const,
      rate_bands: [],
    },
    {
      charge_period: 'MONTHLY' as const,
      charge_type: 'FIXED_PRICE' as const,
      charge_class: 'FEES' as const,
      rate_bands: [{ rate: realTimeRates.monthlyFee, sequence_number: 1 }],
    },
  ];

  if (realTimeRates.hasControlledLoad) {
    rates.push({
      charge_type: 'REAL_TIME' as const,
      charge_class: 'DEDICATED_CIRCUIT_1' as const,
      transaction_type: 'BUY_IMPORT' as const,
      rate_bands: [],
    });
  }

  if (realTimeRates.hasSolarFeedIn) {
    rates.push({
      charge_type: 'REAL_TIME' as const,
      charge_class: 'SUPPLY' as const,
      transaction_type: 'SELL_EXPORT' as const,
      rate_bands: [],
    });
  }

  return rates;
}

function mapFlatRatesToAPI(flatFormData: FlatTariffFormData): TariffRate[] {
  const { ratesAreInclusiveOfGST, seasons } = flatFormData;

  return seasons.map((season, seasonIndex) => {
    let rate = season.rate as number;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    return {
      season_index: seasonIndex + 1,
      charge_type: 'CONSUMPTION_BASED' as const,
      charge_class: 'SUPPLY' as const,
      transaction_type: 'BUY_IMPORT' as const,
      rate_bands: [{ rate, sequence_number: 1 }],
    };
  });
}

function mapAdditionalRatesToAPI(tariffFormData: TariffFormDataState): TariffRate[] {
  const {
    additionalRatesAndDiscounts: { controlledLoads, solarFeedIn },
    basicDetails,
  } = tariffFormData;
  const { ratesAreInclusiveOfGST } =
    tariffFormData[`${basicDetails!.tariffType.toLowerCase()}Rates` as RateFormProperty]!;
  const additionalRates: TariffRate[] = [];

  if (controlledLoads) {
    additionalRates.push(...mapControlledLoadsToAPI(controlledLoads, ratesAreInclusiveOfGST));
  }

  if (solarFeedIn) {
    additionalRates.push({
      charge_type: 'CONSUMPTION_BASED',
      charge_class: 'SUPPLY',
      transaction_type: 'SELL_EXPORT',
      rate_bands: [{ rate: solarFeedIn.rate, sequence_number: 1 }],
    });
  }

  // @TODO: map demand charges when relevant

  return additionalRates;
}

function mapDiscountsToAPI(tariffFormData: TariffFormDataState): LineItemModifier[] {
  const {
    additionalRatesAndDiscounts: { discounts },
  } = tariffFormData;

  const lineItemModifiers: LineItemModifier[] = [];

  if (discounts?.discounts?.length) {
    lineItemModifiers.push(
      ...discounts.discounts.map((d) => ({
        amount: d.value as number,
        discount_type: UI_DISCOUNT_OPTION_TO_DISCOUNT_TYPE[d.type!.value as Discount],
        modifier_type: 'DISCOUNT' as const,
        name: d.type!.value as Discount,
      }))
    );
  }

  // Always add a tax modifier
  lineItemModifiers.push({
    amount: 10,
    discount_type: null,
    name: 'GST',
    modifier_type: 'TAX' as const,
  });

  return lineItemModifiers;
}

function mapControlledLoadsToAPI(controlledLoadFormData: ControlledLoadFormData, ratesAreInclusiveOfGST: boolean) {
  const controlledLoadRates: TariffRate[] = [];
  const { controlledLoad, controlledLoad2, hasControlledLoad2 } = controlledLoadFormData;

  if (controlledLoad.rate) {
    let rate = controlledLoad.rate;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    controlledLoadRates.push({
      charge_type: 'CONSUMPTION_BASED',
      charge_class: 'DEDICATED_CIRCUIT_1',
      transaction_type: 'BUY_IMPORT',
      rate_bands: [{ rate, sequence_number: 1 }],
    });
  }

  if (controlledLoad.hasDailySupplyCharge && controlledLoad.dailySupplyCharge) {
    let rate = controlledLoad.dailySupplyCharge;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    controlledLoadRates.push({
      charge_type: 'FIXED_PRICE',
      charge_class: 'DEDICATED_CIRCUIT_1',
      charge_period: 'DAILY',
      transaction_type: 'BUY_IMPORT',
      rate_bands: [{ rate, sequence_number: 1 }],
    });
  }

  if (hasControlledLoad2 && controlledLoad2.rate) {
    let rate = controlledLoad2.rate;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    controlledLoadRates.push({
      charge_type: 'CONSUMPTION_BASED',
      charge_class: 'DEDICATED_CIRCUIT_2',
      transaction_type: 'BUY_IMPORT',
      rate_bands: [{ rate, sequence_number: 1 }],
    });
  }

  if (controlledLoad2.hasDailySupplyCharge && controlledLoad2.dailySupplyCharge) {
    let rate = controlledLoad2.dailySupplyCharge;
    if (ratesAreInclusiveOfGST) rate = getValueBeforeGST(rate);

    controlledLoadRates.push({
      charge_type: 'FIXED_PRICE',
      charge_class: 'DEDICATED_CIRCUIT_2',
      charge_period: 'DAILY',
      transaction_type: 'BUY_IMPORT',
      rate_bands: [{ rate, sequence_number: 1 }],
    });
  }

  return controlledLoadRates;
}

/**
 * Creates a new `TOURate` which fills the time gaps between all other rates' times of use for a tariff.
 * This only applies when the user specifies that a TOU rate applies at all other times in the form.
 *
 * The general gist of how this is achieved is:
 * 1. Creates a flat array of times of use from all explicitly applicable rates
 * 2. Fills out an array of coverage metadata for each period type (weekdays and weekends)
 * 3. Identifies the gaps between explicit coverage data (if any) and 'fills' them with new `TimeOfUse` objects
 *
 * @param explicitlyApplicableRates - Rates which have explicit times of use.
 * @param rateWhichAppliesAtAllOtherTimes - The rate which the user has indicated applies at all other times (i.e. in-
 *                                          between the explicitly applicable rates).
 * @returns The new `TOURate` object, which applies at all other times.
 */
function createRateWhichAppliesAtAllOtherTimes(
  explicitlyApplicableRates: TOURate[],
  rateWhichAppliesAtAllOtherTimes: TOURate
): TOURate {
  const explicitTimesOfUse = explicitlyApplicableRates.flatMap((rate) => rate.timesOfUse);
  const coverageData: Record<TOUCoverageMeasurementPeriodType, TOUCoverageData[]> = {
    WEEKDAYS: [],
    WEEKENDS: [],
    PUBLIC_HOLIDAYS: [],
  };
  let includesPublicHolidayCoverage = false;

  for (const timeOfUse of explicitTimesOfUse) {
    for (const { value: weekdayType } of timeOfUse.applicablePeriods) {
      if (weekdayType === 'PUBLIC_HOLIDAYS') includesPublicHolidayCoverage = true;
      const fromTime = convertTimeToDate(timeOfUse.fromTime);
      const toTime = convertTimeToDate(timeOfUse.toTime);
      const midnightNextDay = new Date(MIDNIGHT);
      midnightNextDay.setDate(midnightNextDay.getDate() + 1);

      // When toTime is earlier than fromTime, there is a "wrap-around" midnight. To accommodate,
      // ensure we create two separate times of use on the same day.
      if (toTime.getTime() < fromTime.getTime()) {
        const splitCoverageData = [
          {
            fromTime: new Date(MIDNIGHT),
            toTime,
          },
          {
            fromTime,
            toTime: midnightNextDay,
          },
        ];

        if (weekdayType === 'EVERYDAY') {
          coverageData['WEEKDAYS'].push(...splitCoverageData);
          coverageData['WEEKENDS'].push(...splitCoverageData);
        } else {
          coverageData[weekdayType].push(...splitCoverageData);
        }
      } else {
        // If the 'to time' is the same as the 'from time', increment its value by 24 hours
        if (toTime.getTime() === fromTime.getTime()) {
          toTime.setDate(toTime.getDate() + 1);
        }

        if (weekdayType === 'EVERYDAY') {
          coverageData['WEEKDAYS'].push({ fromTime, toTime });
          coverageData['WEEKENDS'].push({ fromTime, toTime });
        } else {
          coverageData[weekdayType].push({ fromTime, toTime });
        }
      }
    }
  }

  let timesOfUse = [
    ...fillGapsAroundTimesOfUse(coverageData, 'WEEKDAYS'),
    ...fillGapsAroundTimesOfUse(coverageData, 'WEEKENDS'),
  ];
  // Fill gaps around times of use on public holidays, if the user explicitly declared at least one time which
  // covers public holidays. Otherwise, we don't need to include public holiday times of use at all (i.e. public
  // holiday rates are the same as their respective weekday/weekend rates)
  if (includesPublicHolidayCoverage) {
    timesOfUse = [...timesOfUse, ...fillGapsAroundTimesOfUse(coverageData, 'PUBLIC_HOLIDAYS')];
  }

  return {
    ...rateWhichAppliesAtAllOtherTimes,
    appliesAtAllOtherTimes: false,
    timesOfUse,
  };
}

/**
 * Identifies and fills gaps around times of use in a specific coverage array.
 *
 * @param coverageData - The object containing a coverage array per period.
 * @param periodType - The type of period (a key of the `coverageData` object).
 * @returns An array of times of use which fill the gaps between explicitly applicable times of use.
 */
function fillGapsAroundTimesOfUse(
  coverageData: Record<TOUCoverageMeasurementPeriodType, TOUCoverageData[]>,
  periodType: TOUCoverageMeasurementPeriodType
): TimeOfUse[] {
  const gapFillTimesOfUse: TimeOfUse[] = [];

  if (coverageData[periodType].length) {
    const sortedCoverageData = [...coverageData[periodType]].sort(
      (a, b) => a.fromTime.getTime() - b.fromTime.getTime()
    );
    const timeOfUseCoverageArray = Array.from(sortedCoverageData.entries());

    // Fill gaps on weekdays and weekends
    for (const [coverageIndex, { fromTime, toTime }] of timeOfUseCoverageArray) {
      // If this is the first item in the coverage array, and it doesn't start at midnight, fill the gap between
      // midnight and this time of use
      if (coverageIndex === 0 && fromTime.getTime() !== MIDNIGHT.getTime()) {
        const { hours, amOrPm } = getTOUTimeHoursFromDate(fromTime);

        gapFillTimesOfUse.push({
          fromTime: MIDNIGHT_TOU_TIME,
          toTime: {
            minutes: fromTime.getMinutes(),
            hours,
            amOrPm,
          },
          applicablePeriods: [
            {
              label: capitalizeFirst(periodType.toLowerCase()) as TOUCoverageMeasurementPeriodTypeLabel,
              value: periodType,
            },
          ],
        });
      }

      if (coverageIndex > 0) {
        // Compare the current item to the previous one, fill the gap between them
        const [, previousTimeOfUsePeriod] = timeOfUseCoverageArray[coverageIndex - 1];

        if (previousTimeOfUsePeriod.toTime.getTime() !== fromTime.getTime()) {
          const { hours: previousPeriodToTimeHours, amOrPm } = getTOUTimeHoursFromDate(previousTimeOfUsePeriod.toTime);
          const { hours: currentFromTimeHours, amOrPm: currentFromTimeAmOrPm } = getTOUTimeHoursFromDate(fromTime);

          gapFillTimesOfUse.push({
            fromTime: {
              minutes: previousTimeOfUsePeriod.toTime.getMinutes(),
              hours: previousPeriodToTimeHours,
              amOrPm,
            },
            toTime: {
              minutes: fromTime.getMinutes(),
              hours: currentFromTimeHours,
              amOrPm: currentFromTimeAmOrPm,
            },
            applicablePeriods: [
              {
                label: capitalizeFirst(periodType.toLowerCase()) as TOUCoverageMeasurementPeriodTypeLabel,
                value: periodType,
              },
            ],
          });
        }
      }

      const midnightNextDay = new Date(MIDNIGHT);
      midnightNextDay.setDate(midnightNextDay.getDate() + 1);
      if (coverageIndex === timeOfUseCoverageArray.length - 1 && toTime.getTime() < midnightNextDay.getTime()) {
        // If this is the last item in the coverage array, and it does not go until midnight of the next day, fill the
        // gap between the end time of the last time of use period and midnight
        const { hours, amOrPm } = getTOUTimeHoursFromDate(toTime);

        gapFillTimesOfUse.push({
          fromTime: {
            minutes: toTime.getMinutes(),
            hours,
            amOrPm,
          },
          toTime: MIDNIGHT_TOU_TIME,
          applicablePeriods: [
            {
              label: capitalizeFirst(periodType.toLowerCase()) as TOUCoverageMeasurementPeriodTypeLabel,
              value: periodType,
            },
          ],
        });
      }
    }
  } else {
    // Add a time of use which applies all day for the period, if there were no explicit times of use provided
    // for this period.
    gapFillTimesOfUse.push({
      fromTime: MIDNIGHT_TOU_TIME,
      toTime: MIDNIGHT_TOU_TIME,
      applicablePeriods: [
        {
          label: capitalizeFirst(periodType.toLowerCase()) as TOUCoverageMeasurementPeriodTypeLabel,
          value: periodType,
        },
      ],
    });
  }

  return gapFillTimesOfUse;
}

/**
 * The API expects a season's from date/month to be inclusive, and to date/month to be exclusive, whereas the form
 * structure uses both as inclusive for the sake of UX.
 * This method just increments the expected 'to' date/month to be exclusive.
 *
 * @param season - The season to adjust the `to_date` for
 * @returns An object containing the new values for `to_date` and `to_month`
 */
function adjustSeasonToDate(season: Season) {
  const { toDate, toMonth } = season;
  const endDateForAPI = new Date(2021, toMonth, toDate);
  endDateForAPI.setDate(endDateForAPI.getDate() + 1);

  return {
    toDate: endDateForAPI.getDate(),
    toMonth: endDateForAPI.getMonth(),
  };
}

/**
 * Simple helper to convert hours and am/pm values for a specified `Date` object. The minutes are independent of this.
 *
 * @param date - The date to convert values for.
 * @returns A structure containing the new values for am/pm and hours
 */
function getTOUTimeHoursFromDate(date: Date): { amOrPm: 'AM' | 'PM'; hours: number } {
  let hours = date.getHours();
  let amOrPm: 'AM' | 'PM' = 'AM';
  if (hours > 12) {
    amOrPm = 'PM';
    hours -= 12;
  }

  if (hours === 0) hours = 12;

  return { hours, amOrPm };
}

/**
 * Converts an hour value from the `TOUTime` 12hr format to the expected 24hr format of the API's times of use.
 *
 * @param touTime - The `TOUTime` object to convert
 * @param type - The type of property this applies to. Relevant because 'to time' is usually 24, not 0, in the API.
 * @returns The adjusted 24-hour value for the hour.
 */
function getHourValue(touTime: TOUTime, type: 'from' | 'to'): number {
  let hourValue = touTime.hours;

  // Midnight when set as a "from" time is 0, but as a "to" time is 24.
  if (hourValue === 12 && touTime.amOrPm === 'AM') {
    hourValue = type === 'from' ? 0 : 24;
  } else if (touTime.amOrPm === 'PM' && hourValue !== 12) {
    // Any time in the PM which is not 12 can be converted to 24h time
    hourValue += 12;
  }

  return hourValue;
}

/**
 * Calculates the value of a numeric rate BEFORE the application of GST, rounded to 4 decimal places.
 * This is necessary because all Australian rates must be saved in their pre-GST format, but sometimes they can appear
 * on customer bills as an after-GST value.
 *
 * @param rate - The rate value to apply GST to
 * @returns The value with GST added, in dollars, rounded to 4 DP.
 */
function getValueBeforeGST(rate: number): number {
  return Math.round((rate / GST_VALUE_DIVISOR) * 10_000) / 10_000;
}
