import React, { useState } from 'react';
import { BOTTOM_NAV_HEIGHT, PAGE_SPACING_PER_VIEWPORT } from '../../../../common/constants';
import EnergyPlan from '../EnergyPlan';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { ImInfo } from 'react-icons/im';
import { DesktopPlansList } from './DesktopPlansList';
import { MobilePlansList } from './MobilePlansList';
import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import infoGreyImg from '../../../../assets/images/info_grey.svg';
import { useViewportType } from '../../../../common/hooks/use-viewport-type';
import MobileTopNav from '../../../../common/components/MobileTopNav';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useGetSiteRetailPlanCheckQuery } from '../../billsApi';
import { selectSite } from '../../../site/siteSlice';
import { BillsOutletContext } from '../../SiteBills';

export default function ComparePlans() {
  const { isParentLoaded, onOpenUploadBillDialog } = useOutletContext<BillsOutletContext>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { isDesktopViewport } = useViewportType();

  return (
    <Box my={4} mx={PAGE_SPACING_PER_VIEWPORT}>
      {isDesktopViewport ? (
        <Button
          variant={'ghost'}
          onClick={() => {
            navigate(`/site/${id}/bills`);
          }}
          mb={3}
          data-testid="back-to-bills-btn"
        >
          <Flex align={'center'}>
            <ChevronLeftIcon w={5} h={5} />
            <Text fontWeight={'bolder'} ml={1}>
              Back to bills
            </Text>
          </Flex>
        </Button>
      ) : (
        <MobileTopNav title={'Compare Plans'} backURL={`/site/${id}/bills`} />
      )}

      <Box
        mb={isDesktopViewport ? undefined : BOTTOM_NAV_HEIGHT}
        mt={isDesktopViewport ? undefined : TOP_NAV_SPACING_AFFORDANCE}
      >
        <EnergyPlan
          onOpenUploadBillDialog={onOpenUploadBillDialog}
          isInPlanComparison
          isParentLoaded={isParentLoaded}
        />

        <PlanComparison />
      </Box>
    </Box>
  );
}

/**
 * Acts as a provider for both desktop and mobile views for the plan comparison page.
 *
 * Performs some logic required by both views.
 *
 * Note that no title is rendered, when this card is always expanded.
 *
 * @constructor
 */
export function PlanComparison({ isAlwaysExpanded }: { isAlwaysExpanded?: boolean }) {
  const site = useSelector(selectSite);
  const { data: rpcData, isLoading } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const [isExpanded, setExpanded] = useState(!!isAlwaysExpanded);
  const { isDesktopViewport } = useViewportType();
  if (isLoading) return <CenteredLoader />;

  // Parent component manages this loading
  const { plans, current_bill_data: currentBillData } = rpcData!;

  const displayedPlans = isExpanded ? plans : plans.slice(0, 3);

  const planListProps = {
    totalNumPlans: plans.length,
    plans: displayedPlans,
    currentBillCost: currentBillData.cost,
    isExpanded,
    setExpanded,
  };

  return (
    <Card mt={5} data-testid="top-three-plans">
      {!isAlwaysExpanded && (
        <Flex mb={3}>
          <Heading mr={2} size={'md'}>
            {isExpanded ? 'All plans by cost' : 'Top 3 plans by cost'}
          </Heading>
          <InfoButton />
        </Flex>
      )}

      {isDesktopViewport ? <DesktopPlansList {...planListProps} /> : <MobilePlansList {...planListProps} />}
    </Card>
  );
}

function InfoButton() {
  const { isDesktopViewport } = useViewportType();
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isDesktopViewport ? (
        <Popover trigger={'hover'}>
          <PopoverTrigger>
            <Box as={'button'} data-testid="plan-by-cost-popover-open-btn">
              <Box as={ImInfo} color={'customBlue.500'} size="22px" />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              maxWidth="400px"
              data-testid="savings-summary-popover"
              p={2}
              rounded={20}
              w="inherit"
              zIndex={4}
            >
              <PopoverArrow />
              <PopoverBody maxWidth={'300px'}>
                <ComparePlansInfoContent />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : (
        <>
          <Box onClick={onOpen} as={'button'} data-testid="plan-by-cost-popover-open-btn">
            <Box as={ImInfo} color={'customBlue.500'} size="22px" />
          </Box>

          <Modal
            motionPreset={'slideInBottom'}
            scrollBehavior={'inside'}
            size={'lg'}
            isCentered
            isOpen={isModalOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent rounded={20} data-testid="plan-by-cost-popover">
              <ModalHeader />
              <ModalCloseButton data-testid="plan-by-cost-popover-close-btn" />
              <ModalBody>
                <ComparePlansInfoContent />
              </ModalBody>
              <ModalFooter />
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

function ComparePlansInfoContent() {
  const site = useSelector(selectSite);
  const { data: rpcData } = useGetSiteRetailPlanCheckQuery(site.site_id);
  // Parent component manages this loading
  const { current_bill_data: currentBillData } = rpcData!;

  return (
    <Box>
      <Flex textAlign={'center'} direction={'column'} justify={'center'} align={'center'}>
        <Image mb={4} src={infoGreyImg} width={'30%'} />

        <Heading my={2} size={'md'}>
          How do we calculate the top plans for you?
        </Heading>

        <Text>
          We look at available energy plans* and then calculate what your bill would have cost you based on your data,
          making it easier to identify the plan that’s best for you!
        </Text>
      </Flex>

      <Heading my={2} size={'sm'}>
        Data used from your last billing period
      </Heading>

      {currentBillData.line_items.map((lineItem, i) => {
        const topValues = lineItem.quantity?.split(' ');
        const bottomValue = lineItem.amount;
        const title = lineItem.name;

        return (
          <Flex
            py={2}
            key={`bill-line-item-${i}`}
            borderTop={i === 0 ? `1px solid` : undefined}
            borderBottom={`1px solid`}
            borderColor={'darkModeBorderColor.500'}
            align={'center'}
            justify={'space-between'}
          >
            <Heading color="headingGrey.500" size={'sm'}>
              {title}
            </Heading>
            <Flex align={'flex-end'} direction={'column'}>
              <Flex align={'flex-end'}>
                <Text mr={1} fontWeight={'bolder'}>
                  {topValues?.[0]}
                </Text>{' '}
                <Text>{topValues?.[1]}</Text>
              </Flex>
              <Text color={'textGrey.500'}>{bottomValue}</Text>
            </Flex>
          </Flex>
        );
      })}

      <Text mt={3} fontSize={'sm'} color={'textGrey.500'}>
        *The energy plans compared are not representative of all plans available in the market. We don’t have access to
        all of the products available in your area: we do not compare all brands in the market, or all products offered
        by all brands. At times certain brands or products may not be available or offered to you.
      </Text>
    </Box>
  );
}
