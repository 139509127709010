import { baseApi } from '../../../app/services/baseApi';

export type TeslaConnection = {
  code: string;
  scope: string;
  redirect_uri: string;
};

export type TeslaConnectionStatus = {
  status: 'success' | 'failure';
  last_modified: string; // datetime string
};

export const teslaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getConnectionStatus: build.query<TeslaConnectionStatus, number>({
      query: (siteId) => `/v1/sites/${siteId}/tesla_connection`,
      providesTags: ['TeslaAccountConnection'],
    }),
    deleteConnection: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
    createConnection: build.mutation<TeslaConnectionStatus, { siteId: number; body: TeslaConnection }>({
      query: ({ siteId, body }) => ({
        url: `/v1/sites/${siteId}/tesla_connection`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TeslaAccountConnection'],
    }),
  }),
});

export const { useGetConnectionStatusQuery, useDeleteConnectionMutation, useCreateConnectionMutation } = teslaApi;
